
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_13x4i_289",
  "refresh": "awsui_refresh_1r9lg_13x4i_289",
  "animating": "awsui_animating_1r9lg_13x4i_303",
  "drawer-closed": "awsui_drawer-closed_1r9lg_13x4i_358",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_13x4i_363",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_13x4i_379",
  "position-bottom": "awsui_position-bottom_1r9lg_13x4i_386",
  "position-side": "awsui_position-side_1r9lg_13x4i_413",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_13x4i_419",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_13x4i_430",
  "open-button-side": "awsui_open-button-side_1r9lg_13x4i_441",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_13x4i_450",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_13x4i_460",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_13x4i_464",
  "content-bottom": "awsui_content-bottom_1r9lg_13x4i_473",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_13x4i_489",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_13x4i_494",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_13x4i_498",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_13x4i_502",
  "content-side": "awsui_content-side_1r9lg_13x4i_507",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_13x4i_517",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_13x4i_523",
  "header": "awsui_header_1r9lg_13x4i_531",
  "header-text": "awsui_header-text_1r9lg_13x4i_541",
  "header-actions": "awsui_header-actions_1r9lg_13x4i_554",
  "divider": "awsui_divider_1r9lg_13x4i_562"
};
  