import { useMemo, useState } from "react";
import { ValueLabel } from "../../client/interfaces";
import {
  EARLIEST_YEAR_FOR_YEAR_QUARTER_MONTH,
  NUM_PAST_YEAR_TO_GENERATE_YEAR_QUARTER_MONTH,
} from "../../data/constants/common";
import {
  generateYearQuarterMonth,
  getCurrentYear,
  isCaseInsensitivePartialStringMatch,
} from "../../helpers";

const MAX_OPTIONS_TO_RETURN = 17;

const COMPLETE_DOCUMENT_YEAR_QUARTER_MONTH_OPTIONS = generateYearQuarterMonth(
  Math.max(
    getCurrentYear() - NUM_PAST_YEAR_TO_GENERATE_YEAR_QUARTER_MONTH,
    EARLIEST_YEAR_FOR_YEAR_QUARTER_MONTH,
  ),
).map((value) => ({ value, label: value }));

export function useDocumentYearQuarterMonthOptions(
  initialFilteringText?: string,
) {
  const [filteringText, setFilteringText] = useState(initialFilteringText);

  const options = useMemo(() => {
    if (!filteringText) {
      return COMPLETE_DOCUMENT_YEAR_QUARTER_MONTH_OPTIONS.slice(
        0,
        MAX_OPTIONS_TO_RETURN,
      );
    }
    const options: ValueLabel[] = [];
    let count = 0;
    for (const option of COMPLETE_DOCUMENT_YEAR_QUARTER_MONTH_OPTIONS) {
      if (isCaseInsensitivePartialStringMatch(option.value, filteringText)) {
        options.push(option);
        count++;
      }
      if (count >= MAX_OPTIONS_TO_RETURN) {
        break;
      }
    }
    return options;
  }, [filteringText]);

  return {
    setDocumentYearQuarterMonthFilteringText: setFilteringText,
    documentYearQuarterMonthOptions: options,
  };
}
