import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { Project } from "../../client/interfaces";

export enum ProjectMetadataName {
  PROJECT_TITLE = "Project title",
  PROJECT_DESCRIPTION = "Project description",
  LAST_UPLOAD_DATE = "Last upload date",
}

export const PROJECT_PROPERTY_KEY: {
  [Key in ProjectMetadataName]: keyof Project;
} = {
  [ProjectMetadataName.PROJECT_TITLE]: "projectTitle",
  [ProjectMetadataName.PROJECT_DESCRIPTION]: "projectDescription",
  [ProjectMetadataName.LAST_UPLOAD_DATE]: "lastUploadDate",
};

export const PROJECT_CREATE_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition = {
  type: "success",
  header: "Success!",
  content: "Project successfully created.",
};

export const PROJECT_EDIT_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition = {
  type: "success",
  header: "Success!",
  content: "Project successfully updated.",
};

export const PROJECT_NO_CHANGES_INFO_MESSAGE: FlashbarProps.MessageDefinition =
  {
    type: "info",
    header: "Info!",
    content: "No changes were made to the project.",
  };

export const PROJECT_DELETE_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition = {
  type: "success",
  header: "Success!",
  content: "Project successfully deleted.",
};

export const CREATE_NEW_PROJECT = "Create new project";
export const EDIT_PROJECT = "Update project";
export const DELETE_PROJECT = "Delete project";
export const DELETE_PROJECT_CONTENT =
  "Proceeding with this action will delete the project.";
export const DELETE_PROJECT_ALERT = {
  header: "This project cannot be deleted",
  content: "Please remove all documents from this project before deleting it.",
};
