import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDownloadDocumentLink } from "../../hooks";
import { RouteParams } from "../../data/constants/common";
import ScreenLoader from "../../components/common/ScreenLoader";
import { handleError } from "../../helpers";
import styles from "./DocumentDownload.module.scss";

const DocumentDownload: FC = () => {
  const { projectId, documentId } = useParams<RouteParams>();
  const {
    data: downloadLink,
    isLoading: isLoadingDownloadLink,
    error: getDownloadLinkError,
  } = useDownloadDocumentLink(projectId, documentId);
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadMessage, setDownloadMessage] = useState("Downloading...");

  useEffect(() => {
    if (downloadLink) {
      location.replace(downloadLink);
      setTimeout(() => {
        setDownloadMessage("You might now close this window.");
        window.close();
      }, 2000);
    }
  }, [downloadLink]);

  useEffect(() => {
    if (getDownloadLinkError) {
      setErrorMessage(handleError(getDownloadLinkError));
    } else {
      setErrorMessage("");
    }
  }, [getDownloadLinkError]);

  const downloadMessageElement = (
    <div className={styles.downloadMessage}>
      {getDownloadLinkError ? (
        <p data-testid="document-download-error-message">
          We are unable to generate the download link for the following reason:
          <br />
          <i>{errorMessage}</i>
          <br />
          Please try again later.
        </p>
      ) : (
        <h1 data-testid="document-download-success-message">
          {downloadMessage}
        </h1>
      )}
    </div>
  );

  return (
    <div data-document-download-selector="main-content">
      {isLoadingDownloadLink ? (
        <div className={styles.loaderPadding}>
          <ScreenLoader message="Generating download link, please wait..." />
        </div>
      ) : (
        downloadMessageElement
      )}
    </div>
  );
};

export default DocumentDownload;
