import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown";
import { DocumentDetailAction } from "./documentDetailTypes";

interface GetDocumentDetailContextMenuItemsProps {
  isEditor?: boolean;
}

export function getDocumentDetailContextMenuItems({
  isEditor,
}: GetDocumentDetailContextMenuItemsProps): ButtonDropdownProps.ItemOrGroup[] {
  return Object.values(DocumentDetailAction)
    .filter((action) =>
      action === DocumentDetailAction.DELETE ||
      action === DocumentDetailAction.DUPLICATE
        ? isEditor
        : true,
    )
    .map((action) => ({
      id: action,
      text: action,
    }));
}
