export function setLocalStorageItem<T extends string>(key: string, value: T) {
  window.localStorage.setItem(key, value);
}

export function getLocalStorageItem<T extends string>(
  key: string,
): T | undefined {
  const item = window.localStorage.getItem(key);
  if (item === null) {
    return undefined;
  }
  return item as T;
}
