import React, { FC, useLayoutEffect, useState } from "react";
import {
  BreadcrumbGroup,
  BreadcrumbGroupProps,
} from "@cloudscape-design/components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NAVIGATION_PATH_LABEL } from "../../data/constants/navigation";
import { RouteParams, RoutePath } from "../../data/constants/common";

interface BreadCrumbsProps {
  pathParamsLabels?: { [k in RouteParams]?: string };
}

const BreadCrumbs: FC<BreadCrumbsProps> = ({ pathParamsLabels }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectTitle, projectId } = useParams<RouteParams>();

  const formatItem = (str: string, index: number): string => {
    if (index === 0) {
      return NAVIGATION_PATH_LABEL[RoutePath.HOME];
    }
    if (decodeURIComponent(str) === projectTitle) {
      return `${NAVIGATION_PATH_LABEL[RoutePath.PROJECT]}: ${decodeURIComponent(str)}`;
    }
    if (str === projectId) {
      return `${NAVIGATION_PATH_LABEL[RoutePath.PROJECT]}: ${pathParamsLabels?.projectId ?? str}`;
    }
    return NAVIGATION_PATH_LABEL[str as RoutePath]
      ? NAVIGATION_PATH_LABEL[str as RoutePath]
      : str;
  };

  const [items, setItems] = useState<BreadcrumbGroupProps.Item[]>([]);
  const { pathname } = location;

  useLayoutEffect(() => {
    const items: BreadcrumbGroupProps.Item[] = [];

    const paths = pathname.split("/");

    paths.forEach((path: string, index: number) => {
      items.push({
        href: paths.slice(0, index + 1).join("/") || "/",
        text: formatItem(path, index),
      });
    });

    setItems(items);
  }, [location.pathname, projectId, pathParamsLabels]);

  const onClickHandler = (
    event: CustomEvent<BreadcrumbGroupProps.ClickDetail>,
  ): void => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  return (
    <BreadcrumbGroup
      items={items}
      onClick={onClickHandler}
      expandAriaLabel="Show path"
      ariaLabel="Breadcrumbs"
    />
  );
};

export default BreadCrumbs;
