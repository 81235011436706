/**
 * Handle error and log it to console.
 * @param error
 * @param messageTitle
 * @param rethrowError
 */
export function handleError(error: unknown, messageTitle?: string): string;
export function handleError(
  error: unknown,
  messageTitle: string,
  rethrowError: false,
): string;
export function handleError(
  error: unknown,
  messageTitle: string,
  rethrowError: true,
): never;
export function handleError(
  error: unknown,
  messageTitle?: string,
  rethrowError?: boolean,
): string | never {
  const message = error instanceof Error ? error.message : "Unknown error";
  const errorMessage = `${messageTitle ?? "Error"}: ${message}`;
  console.error(errorMessage);
  if (rethrowError) {
    throw error;
  }
  return errorMessage;
}
