import omit from "lodash.omit";
import { CreateDocumentCommandInput } from "../../../client/interfaces";
import {
  getFileExtension,
  sortArrayByArraySequence,
  toUnixTimestamp,
} from "../../../helpers";
import { DocumentInputValue } from "./documentFormTypes";

interface DocumentInputValueToCreateDocumentCommandInputProps {
  documentInputValue: DocumentInputValue;
  documentObjectKey: string;
  fileSizeInBytes?: number;
  marketplaceSequence?: string[];
}

export function documentInputValueToCreateDocumentCommandInput({
  documentInputValue,
  documentObjectKey,
  fileSizeInBytes,
  marketplaceSequence = [],
}: DocumentInputValueToCreateDocumentCommandInputProps): CreateDocumentCommandInput {
  const { projectId, fileName, documentExpirationDate, marketplaces, ...rest } =
    documentInputValue;
  return {
    ...omit(rest, "projectDescription"),
    projectId,
    marketplaces: sortArrayByArraySequence(marketplaces, marketplaceSequence),
    fileName: fileName[0].name,
    fileSizeInBytes: fileSizeInBytes ?? fileName[0].size,
    documentType: getFileExtension(fileName[0].name),
    documentLink: documentObjectKey,
    documentExpirationDate: toUnixTimestamp(documentExpirationDate),
    documentId: "00000000000000000000000000", // Dummy documentId
    lastUpdatedBy: "dummy", // Dummy
    lastUpdateDate: 0, // Dummy
    lastUploadDate: 0, // Dummy
  };
}
