
    import './styles.scoped.css';
    export default {
  "content-enter": "awsui_content-enter_gwq0h_1v5t0_157",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_gwq0h_1v5t0_1",
  "trigger-expanded": "awsui_trigger-expanded_gwq0h_1v5t0_179",
  "icon": "awsui_icon_gwq0h_1v5t0_193",
  "root": "awsui_root_gwq0h_1v5t0_207",
  "expand-button": "awsui_expand-button_gwq0h_1v5t0_245",
  "expanded": "awsui_expanded_gwq0h_1v5t0_253",
  "icon-container": "awsui_icon-container_gwq0h_1v5t0_263",
  "icon-container-container": "awsui_icon-container-container_gwq0h_1v5t0_267",
  "wrapper": "awsui_wrapper_gwq0h_1v5t0_271",
  "wrapper-default": "awsui_wrapper-default_gwq0h_1v5t0_279",
  "wrapper-footer": "awsui_wrapper-footer_gwq0h_1v5t0_279",
  "wrapper-navigation": "awsui_wrapper-navigation_gwq0h_1v5t0_283",
  "wrapper-container": "awsui_wrapper-container_gwq0h_1v5t0_286",
  "wrapper-compact": "awsui_wrapper-compact_gwq0h_1v5t0_290",
  "header-deprecated": "awsui_header-deprecated_gwq0h_1v5t0_303",
  "wrapper-expanded": "awsui_wrapper-expanded_gwq0h_1v5t0_325",
  "header": "awsui_header_gwq0h_1v5t0_303",
  "header-wrapper": "awsui_header-wrapper_gwq0h_1v5t0_345",
  "header-actions-wrapper": "awsui_header-actions-wrapper_gwq0h_1v5t0_357",
  "header-button": "awsui_header-button_gwq0h_1v5t0_362",
  "header-container-button": "awsui_header-container-button_gwq0h_1v5t0_362",
  "header-container": "awsui_header-container_gwq0h_1v5t0_362",
  "header-navigation": "awsui_header-navigation_gwq0h_1v5t0_397",
  "header-text": "awsui_header-text_gwq0h_1v5t0_434",
  "content": "awsui_content_gwq0h_1v5t0_157",
  "content-default": "awsui_content-default_gwq0h_1v5t0_441",
  "content-footer": "awsui_content-footer_gwq0h_1v5t0_445",
  "content-expanded": "awsui_content-expanded_gwq0h_1v5t0_449",
  "content-compact": "awsui_content-compact_gwq0h_1v5t0_452",
  "focusable": "awsui_focusable_gwq0h_1v5t0_456",
  "click-target": "awsui_click-target_gwq0h_1v5t0_471"
};
  