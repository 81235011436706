import copy from "copy-to-clipboard";
import { Document, Project } from "../../client/interfaces";
import {
  BASE_URL,
  DOCUMENT_DETAIL_LINK,
  PROJECT_LINK,
} from "../../data/constants/common";

export const CopyLink = {
  getCurrentUrl() {
    return window.location.href;
  },
  getProjectLink(project: Project) {
    return `${BASE_URL}${PROJECT_LINK(project.projectTitle)}`;
  },
  copyProjectLink(project: Project) {
    copy(this.getProjectLink(project));
  },
  copyDocumentDetailLink(project: Project, document: Document) {
    copy(
      `${BASE_URL}${DOCUMENT_DETAIL_LINK(project.projectTitle, document.documentTitle)}`,
    );
  },
};
