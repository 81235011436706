import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import FileUpload, {
  FileUploadProps,
} from "@cloudscape-design/components/file-upload";
import { useInput } from "../../hooks";
import { toFileSize, toISODatetime } from "../../helpers";
import CustomFormField from "./CustomFormField";
import { BaseInputProps, BaseInputRefAttributes } from ".";

export interface FileUploadInputProps<K extends string = string>
  extends BaseInputProps<File[], K>,
    Pick<FileUploadProps, "accept"> {
  /**
   * Used to override the file size in the default value.
   */
  defaultFileSizeInBytes?: number;
}

function FileUploadInput<K extends string = string>(
  {
    label,
    description,
    defaultValue,
    defaultFileSizeInBytes,
    fieldId,
    validate,
    onChange,
    required,
    constraintText,
    ...props
  }: FileUploadInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<File[]>({
      validate,
      initialState: defaultValue,
    });

  const [fileSizeOverride, setFileSizeOverride] = useState(
    defaultFileSizeInBytes,
  );

  const inputRef = useRef<FileUploadProps.Ref>(null);

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
    };
  }, [handleBlur, inputRef]);

  useEffect(() => {
    if (value.length > 0) {
      handleBlur();
    }
  }, [value]);

  function handleChange(value: File[]) {
    setFileSizeOverride(undefined);
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
    handleBlur();
  }

  return (
    <CustomFormField
      label={label}
      required={required}
      description={description}
      errorText={errorText}
      constraintText={constraintText}
    >
      <FileUpload
        value={value}
        ariaRequired={required}
        i18nStrings={{
          uploadButtonText: (multiple) =>
            multiple ? "Choose files" : "Choose file",
          dropzoneText: (multiple) =>
            multiple ? "Drop files to upload" : "Drop file to upload",
          removeFileAriaLabel: () => "Remove file",
          limitShowFewer: "Show fewer files",
          limitShowMore: "Show more files",
          errorIconAriaLabel: "Error",
          warningIconAriaLabel: "Warning",
          formatFileSize: (sizeInBytes) =>
            toFileSize(fileSizeOverride ?? sizeInBytes),
          formatFileLastModified: (lastModified) => toISODatetime(lastModified),
        }}
        showFileLastModified={true}
        showFileSize={true}
        multiple={false}
        onChange={({ detail: { value } }) => {
          handleChange(value);
        }}
        ref={inputRef}
        {...props}
      />
    </CustomFormField>
  );
}

export default forwardRef(FileUploadInput);

export function TypedFileUploadInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, FileUploadInputProps<K>>(
    FileUploadInput,
  );
}
