import React from "react";
import { TableProps } from "@cloudscape-design/components/table";
import { Link } from "react-router-dom";
import { Project } from "../../client/interfaces";
import { toLocalDate } from "../../helpers";
import {
  PROJECT_PROPERTY_KEY,
  ProjectMetadataName,
} from "../common/projectConstant";
import { EMPTY_PLACEHOLDER, PROJECT_LINK } from "../../data/constants/common";

interface ProjectTableColumnDefinitions {
  /**
   * Project filter query string, e.g. "?projectId=XXX".
   * Include the question mark.
   */
  projectFilterQueryString?: string;
  widths?: ReadonlyArray<number>;
  selectedProject?: Project;
}

export const PROJECT_TABLE_COLUMN_DEFINITIONS = ({
  projectFilterQueryString = "",
  widths = [],
  selectedProject,
}: ProjectTableColumnDefinitions = {}): ReadonlyArray<
  TableProps.ColumnDefinition<Project> & { id: ProjectMetadataName }
> => [
  {
    id: ProjectMetadataName.PROJECT_TITLE,
    header: ProjectMetadataName.PROJECT_TITLE,
    cell: (e: Project) => (
      <Link
        to={`${PROJECT_LINK(e.projectTitle)}${projectFilterQueryString}`}
        state={{ project: e }}
      >
        {selectedProject?.projectId === e.projectId ? (
          <b>{e.projectTitle}</b>
        ) : (
          e.projectTitle
        )}
      </Link>
    ),
    isRowHeader: true,
    sortingField: PROJECT_PROPERTY_KEY[ProjectMetadataName.PROJECT_TITLE],
    width: widths[0],
  },
  {
    id: ProjectMetadataName.PROJECT_DESCRIPTION,
    header: ProjectMetadataName.PROJECT_DESCRIPTION,
    cell: (e: Project) => e.projectDescription,
    width: widths[1],
  },
  {
    id: ProjectMetadataName.LAST_UPLOAD_DATE,
    header: ProjectMetadataName.LAST_UPLOAD_DATE,
    cell: (e: Project) => toLocalDate(e.lastUploadDate) ?? EMPTY_PLACEHOLDER,
    sortingField: PROJECT_PROPERTY_KEY[ProjectMetadataName.LAST_UPLOAD_DATE],
    width: widths[2],
  },
];
