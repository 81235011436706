
    import './styles.scoped.css';
    export default {
  "button-dropdown": "awsui_button-dropdown_sne0l_9zu41_141",
  "items-list-container": "awsui_items-list-container_sne0l_9zu41_145",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_sne0l_9zu41_1",
  "rotate-up": "awsui_rotate-up_sne0l_9zu41_164",
  "rotate-down": "awsui_rotate-down_sne0l_9zu41_179",
  "header": "awsui_header_sne0l_9zu41_194",
  "title": "awsui_title_sne0l_9zu41_205",
  "description": "awsui_description_sne0l_9zu41_206",
  "split-trigger-wrapper": "awsui_split-trigger-wrapper_sne0l_9zu41_210",
  "trigger-item": "awsui_trigger-item_sne0l_9zu41_213",
  "trigger-button": "awsui_trigger-button_sne0l_9zu41_216",
  "visual-refresh": "awsui_visual-refresh_sne0l_9zu41_227",
  "split-trigger": "awsui_split-trigger_sne0l_9zu41_210",
  "dropdown-trigger": "awsui_dropdown-trigger_sne0l_9zu41_235",
  "test-utils-button-trigger": "awsui_test-utils-button-trigger_sne0l_9zu41_239"
};
  