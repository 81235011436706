import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import {
  getLocalStorageItem,
  handleError,
  setLocalStorageItem,
} from "../helpers";

/**
 * Use data stored in browser's local storage.
 * Use the same key for get and set so that revalidation happens.
 * automatically on mutation.
 * @param storageKey key to get data from local storage
 */
export function useLocalStorage<T>(storageKey: string) {
  const { data, error, isLoading, mutate } = useSWR(storageKey, (key) => {
    try {
      const item = getLocalStorageItem<string>(key);
      return item !== undefined ? (JSON.parse(item) as T) : undefined;
    } catch (err) {
      handleError(err, "Unable to get data", true);
    }
  });
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

/**
 * Save data to browser's local storage.
 * Use the same key for get and set so that revalidation happens.
 * automatically on mutation.
 * @param storageKey key to store data in local storage
 */
export function useSetLocalStorage<T>(storageKey: string) {
  const { data, error, trigger, isMutating } = useSWRMutation(
    storageKey,
    (key, { arg }: { arg: T }) => {
      try {
        setLocalStorageItem<string>(key, JSON.stringify(arg));
        return arg;
      } catch (err) {
        handleError(err, "Unable to store data", true);
      }
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useLocalStoragePreferences<T>(storageKey: string) {
  const { data: preferences } = useLocalStorage<T>(storageKey);
  const { trigger: setPreferences } = useSetLocalStorage<T>(storageKey);
  return {
    preferences,
    setPreferences,
  };
}
