import React, { FC, useRef } from "react";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Form from "@cloudscape-design/components/form";
import { Project } from "../../client/interfaces";
import { BaseInputRefAttributes } from "../../components/inputs";
import { objectMap } from "../../helpers";
import { useFlashbarMessages, useProjects } from "../../hooks";
import FlashBarMessages from "../../components/common/FlashbarMessages";
import { useMetadataOptions } from "../common/form/metadataOptions";
import { ProjectMetadataName } from "../common/projectConstant";
import { useDebouncedProjectTitle } from "../common/form/debouncedProjectTitle";
import {
  PROJECT_FORM_INPUTS,
  ProjectFormInputsProps,
  ProjectFormSubmitAction,
  PROJECT_FORM_PROJECT_INFORMATION,
  ProjectFormSubmitDetail,
  useProjectFormChange,
  useProjectFormSubmit,
} from "./projectForm";
import styles from "./ProjectForm.module.scss";

export interface ProjectFormProps {
  /**
   * Default project to populate the inputs.
   */
  project?: Project;
  onCancel?: () => void;
  onSubmit?: (detail: ProjectFormSubmitDetail) => void;
  submitAction?: ProjectFormSubmitAction;
}

const ProjectForm: FC<ProjectFormProps> = ({
  project,
  onCancel,
  onSubmit,
  submitAction = ProjectFormSubmitAction.CREATE,
}) => {
  const { data: projects } = useProjects();
  const { messages, addErrorMessage } = useFlashbarMessages();

  const {
    handleChange,
    defaultProjectInputValue,
    projectInputValue,
    projectFormIsError,
    projectFormIsModified,
  } = useProjectFormChange({
    project,
    submitAction,
    onProjectTitleChange: (projectTitle) =>
      debounceProjectTitleExists(projectTitle),
  });

  const { projectTitleExists, debounceProjectTitleExists } =
    useDebouncedProjectTitle({
      allProjects: projects,
      initialProjectTitleExists: !project,
      projectFormIsModified,
      onDebouncedProjectTitleChange: (projectTitle) => {
        const validationOutput =
          projectFormInput[ProjectMetadataName.PROJECT_TITLE].props.validate?.(
            projectTitle,
          );
        projectFormIsError.current.projectTitle = !(
          validationOutput?.isValid ?? true
        );
        if (projectTitle) {
          refsMap?.projectTitle?.current?.validate();
        }
      },
    });

  const { handleSubmit, isSubmitting } = useProjectFormSubmit({
    project,
    projectInputValue,
    projectFormIsError,
    projectFormIsModified,
    submitAction,
    onSubmitAction: () =>
      Object.values(refsMap).forEach((ref) => {
        ref.current?.validate();
      }),
    onSubmitSuccess: onSubmit,
    onSubmitError: focusTopMostError,
    addErrorMessage,
  });

  const { metadataOptions } = useMetadataOptions({ projects });

  // use refs (for external control)
  const refsMap = objectMap(defaultProjectInputValue, () =>
    useRef<BaseInputRefAttributes>(null),
  );

  function handleCancel() {
    Object.values(refsMap).forEach((ref) => {
      ref.current?.reset();
    });
    onCancel?.();
  }

  function focusTopMostError() {
    for (const input of [...projectInformationInputs]) {
      if (projectFormIsError.current[input.props.fieldId]) {
        return refsMap[input.props.fieldId]?.current?.focus();
      }
    }
  }

  // Create all form input components and props
  // Include all external status control, validation, and form options here
  const projectFormInputsProps: ProjectFormInputsProps = {
    options: metadataOptions,
    defaultValue: defaultProjectInputValue,
    externalValidate: {
      projectTitle: () => !projectTitleExists.current,
    },
  };
  const projectFormInput = PROJECT_FORM_INPUTS(projectFormInputsProps);

  const projectInformationInputs = PROJECT_FORM_PROJECT_INFORMATION(
    projectFormInputsProps,
  );

  return (
    <div data-testid="project-form" className={styles.projectForm}>
      <Form
        header={
          <FlashBarMessages
            data-testid="project-form-flashbar-messages"
            data-project-form-selector="flashbar-messages"
            messages={messages}
          />
        }
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              data-testid="project-form-cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              data-testid="project-form-submit-button"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              {submitAction}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Container
            data-testid="project-form-project-information"
            header={<Header variant="h3">Project information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {projectInformationInputs.map((input) => {
                const {
                  component: ProjectFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  <ProjectFormComponent
                    {...rest}
                    data-testid={`project-form-${fieldId}`}
                    fieldId={fieldId}
                    key={fieldId}
                    ref={refsMap[fieldId]}
                    onChange={handleChange}
                  />
                );
              })}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
    </div>
  );
};

export default ProjectForm;
