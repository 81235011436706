import { filesize } from "filesize";
import { UNKNOWN_FILE_EXTENSION } from "../data/constants/common";

export function toFileSize(sizeInBytes: number): string {
  return filesize(sizeInBytes);
}

export function getFileExtension(fileName: string): string {
  if (fileName.search(/\./) === -1) {
    return UNKNOWN_FILE_EXTENSION;
  }
  return fileName.split(".").pop() || UNKNOWN_FILE_EXTENSION;
}
