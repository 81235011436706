import useSWRImmutable from "swr/immutable";
import { Amplify } from "aws-amplify";
import { getSettings } from "../client/api/settings";
import { getAuthConfig } from "../data/config/authConfig";

export function useSettingsWithAuthConfigured() {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    "getSettings",
    async () => {
      const settings = await getSettings();
      Amplify.configure({ ...Amplify.getConfig(), ...getAuthConfig(settings) });
      return settings;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
