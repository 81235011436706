import React, { FC, ReactElement } from "react";
import AppRoutes from "../data/constants/routes";

const ViewPort: FC = (): ReactElement | null => {
  return (
    <div data-testid="view-port">
      <AppRoutes />
    </div>
  );
};

export default ViewPort;
