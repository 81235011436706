
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_ezv7d_141",
  "item": "awsui_item_dwuol_ezv7d_146",
  "checkbox": "awsui_checkbox_dwuol_ezv7d_150",
  "filter": "awsui_filter_dwuol_ezv7d_159",
  "trigger": "awsui_trigger_dwuol_ezv7d_164",
  "layout-strut": "awsui_layout-strut_dwuol_ezv7d_170",
  "list-bottom": "awsui_list-bottom_dwuol_ezv7d_176",
  "selected-icon": "awsui_selected-icon_dwuol_ezv7d_180",
  "show-label-tag": "awsui_show-label-tag_dwuol_ezv7d_184",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_ezv7d_188",
  "inline-token-list": "awsui_inline-token-list_dwuol_ezv7d_195",
  "inline-token": "awsui_inline-token_dwuol_ezv7d_188",
  "visual-refresh": "awsui_visual-refresh_dwuol_ezv7d_225",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_ezv7d_232",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_ezv7d_238",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_ezv7d_242",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_dwuol_ezv7d_248"
};
  