import PropertyFilter, {
  PropertyFilterProps,
} from "@cloudscape-design/components/property-filter";

import React, { useEffect, useState } from "react";
import styles from "./TablePropertyFilter.module.css";

export interface TablePropertyFilterProps
  extends Pick<
    PropertyFilterProps,
    "filteringProperties" | "filteringPlaceholder" | "filteringOptions"
  > {
  onFilterChange?: (query: PropertyFilterProps.Query) => void;
  itemMatchCount?: number;
  /**
   * Use to override the query state. If provided, `setQueryOverride` must be provided.
   * @default use internal control
   */
  queryOverride?: PropertyFilterProps.Query;
  /**
   * Use to override the set query state. If provided, `queryOverride` must be provided.
   * @default use internal control
   */
  setQueryOverride?: React.Dispatch<
    React.SetStateAction<PropertyFilterProps.Query>
  >;
  onFilteringLoadItems?: PropertyFilterProps["onLoadItems"];
  filteringLoading?: boolean;
  filteringError?: boolean;
}

const TablePropertyFilter = ({
  onFilterChange: onFilterChange,
  itemMatchCount,
  filteringProperties,
  filteringOptions,
  filteringPlaceholder,
  queryOverride,
  setQueryOverride,
  onFilteringLoadItems,
  filteringLoading,
  filteringError,
  ...props
}: TablePropertyFilterProps) => {
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and",
  });

  const [statusType, setStatusType] =
    useState<PropertyFilterProps["filteringStatusType"]>("pending");

  useEffect(() => {
    if (filteringLoading) {
      setStatusType("loading");
    } else if (filteringError) {
      setStatusType("error");
    } else {
      setStatusType("finished");
    }
  }, [filteringLoading, filteringError]);

  return (
    <div className={styles.dropdownHeight}>
      <PropertyFilter
        data-testid="table-property-filter"
        data-selector="table-property-filter"
        onChange={({ detail }) => {
          (setQueryOverride ?? setQuery)(detail);
          onFilterChange?.(detail);
        }}
        onLoadItems={onFilteringLoadItems}
        query={queryOverride ?? query}
        countText={
          itemMatchCount != undefined ? `${itemMatchCount} matches` : undefined
        }
        hideOperations
        filteringPlaceholder={filteringPlaceholder}
        filteringProperties={filteringProperties}
        filteringOptions={filteringLoading ? [] : filteringOptions}
        filteringAriaLabel={filteringPlaceholder}
        filteringStatusType={statusType}
        filteringLoadingText="Loading..."
        filteringRecoveryText="Retry"
        filteringErrorText="Error fetching results"
        i18nStrings={{
          filteringAriaLabel: "your choice",
          dismissAriaLabel: "Dismiss",
          filteringPlaceholder: "Filter by text, property or value",
          groupValuesText: "Values",
          groupPropertiesText: "Properties",
          operatorsText: "Operators",
          operationAndText: "and",
          operationOrText: "or",
          operatorLessText: "Less than",
          operatorLessOrEqualText: "Less than or equal",
          operatorGreaterText: "Greater than",
          operatorGreaterOrEqualText: "Greater than or equal",
          operatorContainsText: "Contains",
          operatorDoesNotContainText: "Does not contain",
          operatorEqualsText: "Equals",
          operatorDoesNotEqualText: "Does not equal",
          editTokenHeader: "Edit filter",
          propertyText: "Property",
          operatorText: "Operator",
          valueText: "Value",
          cancelActionText: "Cancel",
          applyActionText: "Apply",
          allPropertiesLabel: "All properties",
          tokenLimitShowMore: "Show more",
          tokenLimitShowFewer: "Show fewer",
          clearFiltersText: "Clear Filters",
          removeTokenButtonAriaLabel: (token) =>
            `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
          enteredTextLabel: (text) => `Use: "${text}"`,
        }}
        {...props}
      />
    </div>
  );
};

export default TablePropertyFilter;
