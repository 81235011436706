import React, { FC, useContext, useEffect, useRef, useState } from "react";
import SplitPanel from "@cloudscape-design/components/split-panel";
import { CollectionPreferencesProps } from "@cloudscape-design/components/collection-preferences";
import { NonCancelableCustomEvent } from "@cloudscape-design/components/interfaces";
import { useSearchParams } from "react-router-dom";
import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import { useDebouncedCallback } from "use-debounce";
import ButtonDropdown, {
  ButtonDropdownProps,
} from "@cloudscape-design/components/button-dropdown";
import {
  useSplitPanel,
  useProjects,
  useLocalStoragePreferences,
  useDocumentMetadataOptions,
  useGetDocumentTitles,
} from "../../hooks";
import CustomAppLayout from "../../components/layout/CustomAppLayout";
import {
  GetProjectsCommandInput,
  Project,
  UserRole,
} from "../../client/interfaces";
import TableHeader from "../../components/header/TableHeader";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../common/documentConstant";
import {
  DEFAULT_DEBOUNCE_DELAY_MILLIS,
  FREE_FILTER_TERM_KEY,
  MINIMUM_DOCUMENT_TITLE_LENGTH,
  VALID_OPERATORS_REGEX,
} from "../../data/constants/common";
import { AuthContext } from "../../context/AuthContext";
import ServerClientHybridCollectionTable from "../../components/table/ServerClientHybridCollectionTable";
import { MessageContext } from "../../context/MessagingContext";
import {
  GET_DOCUMENT_METADATA_OPTION_ERROR,
  GET_PROJECTS_ERROR,
} from "../../data/constants/errorMessage";
import { useExportDocumentMetadata } from "../common/documentMetadataExport";
import { toDateComparisonOperator } from "../../helpers";
import {
  PROJECT_PROPERTY_KEY,
  ProjectMetadataName,
} from "../common/projectConstant";
import { PROJECT_TABLE_COLUMN_DEFINITIONS } from "../common/projectTableColumnDefinition";
import { useDocumentYearQuarterMonthOptions } from "../common/documentOption";
import { useTableColumnWidth } from "../common/tableColumnWidth";
import { useDocumentDelete } from "../common/documentDelete";
import { useDashboardSplitPanelElement } from "../panel/dashboardSplitPanelElement";
import { useDocumentDownload } from "../common/documentDownload";
import { CopyLink } from "../common/shareLinkCopy";
import { useProjectDelete } from "../common/projectDelete";
import {
  ProjectDashboardAction,
  ProjectDashboardCreateOption,
} from "./projectDashboard/projectDashboardTypes";
import { getProjectDashboardContextMenuItems } from "./projectDashboard/projectDashboardContextMenu";
import {
  PROJECT_TABLE_FILTERING_OPTIONS,
  PROJECT_TABLE_FILTERING_PROPERTIES,
  PROJECT_TABLE_PREFERENCES,
  PROJECT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS,
} from "./projectDashboard/projectDashboardTableConfig";

const PROJECT_TABLE_PREFERENCES_KEY = "projectTablePreferences";
const PROJECT_TABLE_COLUMN_WIDTHS_KEY = "projectTableColumnWidths";
const PROJECT_TABLE_SPLIT_PANEL_SIZE_KEY = "documentTableSplitPanelSize";

function toPropertyFilterQuery(
  urlSearchParams: URLSearchParams,
): PropertyFilterProps.Query {
  const tokens: PropertyFilterProps.Query["tokens"][number][] = [];
  urlSearchParams.forEach((value, key) => {
    const matchedValue = value.match(VALID_OPERATORS_REGEX);
    if (matchedValue) {
      tokens.push({
        operator: matchedValue[1] ?? "=",
        propertyKey: key === FREE_FILTER_TERM_KEY ? undefined : key,
        value: matchedValue[2],
      });
    }
  });
  return {
    operation: "and",
    tokens,
  };
}

function toGetProjectsFilter(
  urlSearchParams: URLSearchParams,
): GetProjectsCommandInput {
  const filter: GetProjectsCommandInput = {};
  type GetProjectsCommandInputStringKey = keyof Omit<
    GetProjectsCommandInput,
    "documentExpirationDate" | "lastUploadDate"
  >;
  urlSearchParams.forEach((value, key) => {
    // value includes operator prefix
    const matchedValue = value.match(VALID_OPERATORS_REGEX);
    if (matchedValue) {
      const filterValue = matchedValue[2]; // value without operator prefix
      if (key === "documentExpirationDate" || key === "lastUploadDate") {
        // Only `documentExpirationDate` and `lastUploadDate` support DateComparison in API
        const operator = toDateComparisonOperator(matchedValue[1]); // matchedValue[1] is the operator
        if (operator) {
          filter[key] = { date: parseInt(filterValue), operator };
        }
      } else {
        filter[key as GetProjectsCommandInputStringKey] = filterValue;
      }
    }
  });
  return filter;
}

function toURLSearchParams(query: PropertyFilterProps.Query): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  query.tokens.forEach((token) => {
    urlSearchParams.set(
      token.propertyKey ?? FREE_FILTER_TERM_KEY,
      `${token.operator}${token.value}`,
    );
  });
  return urlSearchParams;
}

const ProjectDashboard: FC = () => {
  const { preferences, setPreferences } =
    useLocalStoragePreferences<CollectionPreferencesProps.Preferences>(
      PROJECT_TABLE_PREFERENCES_KEY,
    );
  const { widths, onColumnWidthsChange, tableKeyForWidths } =
    useTableColumnWidth(PROJECT_TABLE_COLUMN_WIDTHS_KEY);

  const [getProjectsFilter, setGetProjectsFilter] =
    useState<GetProjectsCommandInput>({});
  const {
    data: projects,
    isLoading: isLoadingProjects,
    mutate: reloadProjects,
    error: getProjectsError,
  } = useProjects(getProjectsFilter);
  const { data: metadataOptions, error: getDocumentMetadataOptionsError } =
    useDocumentMetadataOptions();

  // Document title option states
  const [documentTitleToCheck, setDocumentTitleToCheck] = useState<
    string | undefined
  >(undefined);
  const { data: documentTitleResult, isLoading: isLoadingDocumentTitleResult } =
    useGetDocumentTitles(documentTitleToCheck);
  const debounceDocumentTitleToCheck = useDebouncedCallback(
    (value?: string) =>
      setDocumentTitleToCheck(
        (value ?? "").length >= MINIMUM_DOCUMENT_TITLE_LENGTH
          ? value
          : undefined,
      ),
    DEFAULT_DEBOUNCE_DELAY_MILLIS,
  );

  // Document year/quarter/month option states
  const {
    setDocumentYearQuarterMonthFilteringText,
    documentYearQuarterMonthOptions,
  } = useDocumentYearQuarterMonthOptions();

  const { alias, isEditor } = useContext(AuthContext);
  const { addErrorMessage, addMessage } = useContext(MessageContext);

  // Download document
  const { downloadDocument } = useDocumentDownload({
    addErrorMessage,
  });

  // Export document metadata
  const { exportDocumentMetadata, isExporting, refreshBatchDocuments } =
    useExportDocumentMetadata({
      addErrorMessage,
    });

  // Table selection states
  const [tableSelectedItem, setTableSelectedItem] = useState<Project[]>([]);

  // Delete document states
  const { deleteDocument, documentDeleteConfirmationModal } = useDocumentDelete(
    {
      modalDataTestId: "project-dashboard-delete-document-modal",
      addMessage,
      addErrorMessage,
      onDocumentChange: () => closeSplitPanel(),
    },
  );

  // Delete project states
  const { deleteProject, projectDeleteConfirmationModal } = useProjectDelete({
    modalDataTestId: "project-dashboard-delete-project-modal",
    addMessage,
    addErrorMessage,
    onProjectChange: (project) => {
      if (
        openedProjects?.length === 1 &&
        project.projectId === openedProjects[0].projectId
      ) {
        closeSplitPanel();
      }
      if (project.projectId === tableSelectedItem[0]?.projectId) {
        setTableSelectedItem([]);
      }
      reloadProjects();
    },
  });

  // Split panel states
  const divRef = useRef<HTMLDivElement>(null);
  const {
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    setSplitPanelOpen,
    onSplitPanelToggle,
  } = useSplitPanel(PROJECT_TABLE_SPLIT_PANEL_SIZE_KEY);
  const {
    splitPanelElement,
    splitPanelHeader,
    closeSplitPanel,
    createDocument,
    createProject,
    openedProjects,
    editProject,
    viewProjectDetail,
  } = useDashboardSplitPanelElement({
    setSplitPanelOpen,
    dashboardDivRef: divRef,
    splitPanelSelector: "[data-project-dashboard-selector=\"split-panel\"]",
    onDocumentChange: () => reloadProjects(),
    onProjectChange: () => reloadProjects(),
    handleDownloadDocument: ({ document }) =>
      downloadDocument(document.projectId, document.documentId),
    handleDeleteDocument: ({ document }) => deleteDocument(document),
    handleExportDocumentMetadata: ({ project, document }) =>
      exportDocumentMetadata(project, [document]),
    handleExportDocumentMetadataByProject: ({ project, documents }) =>
      documents
        ? exportDocumentMetadata(project, documents)
        : exportDocumentMetadata([project]),
    handleDeleteProject: deleteProject,
  });

  // Filter states
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and",
  });

  useEffect(() => {
    const updatedQuery = toPropertyFilterQuery(searchParams);
    setQuery(updatedQuery);
    setGetProjectsFilter(toGetProjectsFilter(searchParams));
    handleFilterChange(updatedQuery);
  }, [searchParams]);

  useEffect(() => {
    if (!openedProjects) {
      setTableSelectedItem([]);
    }
  }, [openedProjects]);

  useEffect(() => {
    if (openedProjects && tableSelectedItem.length === 0) {
      closeSplitPanel();
    }
    if (tableSelectedItem.length > 0) {
      viewProjectDetail({
        projects: tableSelectedItem as [Project, ...Project[]],
      });
    }
  }, [tableSelectedItem]);

  useEffect(() => {
    if (getDocumentMetadataOptionsError) {
      addErrorMessage?.(
        getDocumentMetadataOptionsError,
        GET_DOCUMENT_METADATA_OPTION_ERROR,
      );
    }
    if (getProjectsError) {
      addErrorMessage?.(getProjectsError, GET_PROJECTS_ERROR);
    }
  }, [getProjectsError, getDocumentMetadataOptionsError]);

  function handleFilterChange(query: PropertyFilterProps.Query) {
    setSearchParams(toURLSearchParams(query));
  }

  function handleTableHeaderActionItemClick(
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) {
    const { id } = event.detail;
    switch (id) {
    case ProjectDashboardAction.EDIT:
      tableSelectedItem.length === 1 &&
          editProject({ project: tableSelectedItem[0] });
      break;
    case ProjectDashboardAction.DELETE:
      deleteProject({ project: tableSelectedItem[0] });
      break;
    case ProjectDashboardAction.EXPORT:
      exportDocumentMetadata(tableSelectedItem);
      break;
    case ProjectDashboardAction.SHARE_SELECTED:
      tableSelectedItem.length === 1 &&
          CopyLink.copyProjectLink(tableSelectedItem[0]);
      break;
    }
  }

  function handleTableHeaderCreateOptionItemClick(
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) {
    const { id } = event.detail;
    switch (id) {
    case ProjectDashboardCreateOption.PROJECT:
      createProject();
      break;
    case ProjectDashboardCreateOption.DOCUMENT:
      createDocument();
      break;
    }
  }

  function handleOnFilteringLoadItems({
    detail: { filteringText, filteringProperty },
  }: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>) {
    if (
      filteringProperty?.key ===
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TITLE]
    ) {
      debounceDocumentTitleToCheck(filteringText);
    }
    if (
      filteringProperty?.key ===
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH]
    ) {
      setDocumentYearQuarterMonthFilteringText(filteringText);
    }
  }

  const headerButtons = [
    <ButtonDropdown
      key="actions"
      data-testid="project-table-header-actions"
      items={getProjectDashboardContextMenuItems({
        isExporting,
        numSelectedItems: tableSelectedItem.length,
        isEditor,
      })}
      disabled={tableSelectedItem.length === 0}
      disabledReason="No projects selected"
      onItemClick={handleTableHeaderActionItemClick}
      loading={isExporting}
    >
      Actions
    </ButtonDropdown>,
    <ButtonDropdown
      key="upload"
      data-testid="handle-create-project-or-document"
      data-roles={`${UserRole.ADMIN} ${UserRole.AUTHOR}`}
      items={Object.values(ProjectDashboardCreateOption).map((option) => ({
        id: option,
        text: option,
      }))}
      onItemClick={handleTableHeaderCreateOptionItemClick}
      variant="primary"
    >
      Create project or document
    </ButtonDropdown>,
  ];

  return (
    <div data-testid="project-dashboard" ref={divRef}>
      <CustomAppLayout
        content={
          <ServerClientHybridCollectionTable
            key={tableKeyForWidths}
            copyUrlProps={{
              textToCopy: CopyLink.getCurrentUrl(),
              displayText:
                query.tokens.length === 0
                  ? "Copy URL"
                  : "Copy URL with current filters",
            }}
            variant="full-page"
            selectionType="multi"
            selectedItems={tableSelectedItem}
            onSelectionChange={({ detail }) =>
              setTableSelectedItem(detail.selectedItems)
            }
            withClientSidePagination={true}
            withClientSideSorting={true}
            sortingColumn={{
              sortingField:
                PROJECT_PROPERTY_KEY[ProjectMetadataName.LAST_UPLOAD_DATE],
            }}
            sortingDescending={true}
            filteringPlaceholder="Find projects by entering texts, keywords, property, or value"
            items={projects ?? []}
            itemMatchCount={projects?.length}
            loading={isLoadingProjects}
            preferences={preferences ?? PROJECT_TABLE_PREFERENCES}
            onPreferencesConfirm={(detail) => setPreferences(detail)}
            columnDefinitions={PROJECT_TABLE_COLUMN_DEFINITIONS({
              projectFilterQueryString: "",
              widths,
              selectedProject:
                openedProjects?.length === 1 ? openedProjects[0] : undefined,
            })}
            onColumnWidthsChange={onColumnWidthsChange}
            contentDisplayOptions={
              PROJECT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS
            }
            filteringProperties={PROJECT_TABLE_FILTERING_PROPERTIES}
            filteringOptions={PROJECT_TABLE_FILTERING_OPTIONS({
              options: {
                ...metadataOptions,
                documentYearQuarterMonth: documentYearQuarterMonthOptions,
                projects: projects?.map((project) => ({
                  value: project.projectTitle,
                })),
                authors: alias && isEditor ? [{ value: alias }] : [],
                documentTitles: documentTitleResult?.map((title) => ({
                  value: title,
                })),
              },
            })}
            filteringLoading={isLoadingDocumentTitleResult}
            onFilteringLoadItems={handleOnFilteringLoadItems}
            onFilterChange={handleFilterChange}
            queryOverride={query}
            setQueryOverride={setQuery}
            empty="No projects"
            trackBy={(item) => item.projectId}
            header={
              <TableHeader
                showRefreshButton={true}
                refreshButtonOnClick={() => {
                  reloadProjects();
                  refreshBatchDocuments();
                }}
                buttons={headerButtons}
              >
                Projects
              </TableHeader>
            }
          />
        }
        splitPanel={
          <SplitPanel
            data-project-dashboard-selector="split-panel"
            header={splitPanelHeader}
            closeBehavior="hide"
            hidePreferencesButton={true}
          >
            {splitPanelElement}
          </SplitPanel>
        }
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={{ position: "side" }}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={(e) => {
          onSplitPanelToggle(e);
          closeSplitPanel();
        }}
      />
      {documentDeleteConfirmationModal}
      {projectDeleteConfirmationModal}
    </div>
  );
};

export default ProjectDashboard;
