import React, { createContext, FC } from "react";
import { FlashbarProps } from "@cloudscape-design/components";
import { useFlashbarMessages } from "../hooks";

export type MessagesContextType = {
  messages?: FlashbarProps.MessageDefinition[];
  addMessage?: (message: FlashbarProps.MessageDefinition) => void;
  addErrorMessage?: (error: unknown, messageTitle?: string) => void;
};

export const MessageContext = createContext<MessagesContextType>({});

interface MessagesProviderProps {
  children: React.ReactNode;
}

const MessagesProvider: FC<MessagesProviderProps> = ({ children }) => {
  const { messages, addMessage, addErrorMessage } = useFlashbarMessages();

  return (
    <MessageContext.Provider value={{ messages, addMessage, addErrorMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessagesProvider;
