import { KeyValuePairsProps } from "@cloudscape-design/components/key-value-pairs";
import { Project } from "../../../client/interfaces";
import { arrayToDict } from "../../../helpers";
import { ProjectMetadataName } from "../../common/projectConstant";
import { PROJECT_TABLE_COLUMN_DEFINITIONS } from "../../common/projectTableColumnDefinition";

const PROJECT_TABLE_COLUMN_DEFINITIONS_BY_ID = arrayToDict<
  ReturnType<typeof PROJECT_TABLE_COLUMN_DEFINITIONS>[0],
  ProjectMetadataName
>(PROJECT_TABLE_COLUMN_DEFINITIONS(), "id");

export const PROJECT_DETAIL_PROJECT_INFORMATION: (
  project: Project,
) => KeyValuePairsProps.Item[] = (project: Project) =>
  [ProjectMetadataName.PROJECT_DESCRIPTION].map((metadataName) => {
    return {
      label: metadataName,
      value: PROJECT_TABLE_COLUMN_DEFINITIONS_BY_ID[metadataName].cell(project),
    };
  });
