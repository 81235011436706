import React, { FC } from "react";
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@cloudscape-design/components/collection-preferences";
import { DEFAULT_PAGE_SIZE } from "../../data/constants/common";

export interface TableCollectionPreferencesProps
  extends Pick<CollectionPreferencesProps, "preferences"> {
  onPreferencesConfirm?: (
    detail: CollectionPreferencesProps.Preferences,
  ) => void;
  contentDisplayOptions: ReadonlyArray<CollectionPreferencesProps.ContentDisplayOption>;
}

const TableCollectionPreferences: FC<TableCollectionPreferencesProps> = ({
  preferences,
  onPreferencesConfirm,
  contentDisplayOptions,
}) => {
  return (
    <CollectionPreferences
      title="Preferences"
      data-testid="table-collection-preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      onConfirm={({ detail }) => onPreferencesConfirm?.(detail)}
      preferences={preferences}
      pageSizePreference={{
        title: "Items per page",
        options: [
          { value: DEFAULT_PAGE_SIZE, label: `${DEFAULT_PAGE_SIZE}` },
          { value: 25, label: "25" },
          { value: 50, label: "50" },
          { value: 100, label: "100" },
        ],
      }}
      contentDisplayPreference={{
        title: "Column preferences",
        description: "Customize the columns visibility and order.",
        options: contentDisplayOptions,
      }}
    />
  );
};

export default TableCollectionPreferences;
