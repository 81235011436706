import {
  DocumentMetadataOptionsForInputs,
  Project,
  ValueLabel,
} from "../../../client/interfaces";

interface UseMetadataOptionsProps {
  documentMetadataOptions?: DocumentMetadataOptionsForInputs;
  projects?: Project[];
  newProjectTitle?: ValueLabel;
  documentTitles?: string[];
  documentYearQuarterMonthOptions?: ValueLabel[];
}

export function useMetadataOptions({
  documentMetadataOptions,
  projects,
  newProjectTitle,
  documentTitles,
  documentYearQuarterMonthOptions,
}: UseMetadataOptionsProps) {
  const metadataOptions: DocumentMetadataOptionsForInputs = {
    ...documentMetadataOptions,
    projects: [
      ...(newProjectTitle ? [newProjectTitle] : []),
      ...(projects?.map((project) => ({
        value: project.projectId,
        label: project.projectTitle,
      })) ?? []),
    ],
    documentYearQuarterMonth: documentYearQuarterMonthOptions,
    documentTitles: documentTitles?.map((title) => ({ value: title })),
  };
  return {
    metadataOptions,
  };
}
