import React, { FC, useContext, useEffect } from "react";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import ButtonDropdown, {
  ButtonDropdownProps,
} from "@cloudscape-design/components/button-dropdown";
import Container from "@cloudscape-design/components/container";
import { Document, Project, UserRole } from "../../client/interfaces";
import KeyValuePairCard from "../../components/card/KeyValuePairCard";
import FlashBarMessages, {
  FlashbarMessagesProps,
} from "../../components/common/FlashbarMessages";
import {
  useDocumentMetadataOptions,
  useFlashbarMessages,
  useProject,
} from "../../hooks";
import { GET_PROJECT_ERROR } from "../../data/constants/errorMessage";
import { AuthContext } from "../../context/AuthContext";
import styles from "./DocumentDetail.module.scss";
import {
  DOCUMENT_DETAIL_ADDITIONAL_INFORMATION,
  DOCUMENT_DETAIL_GENERAL_INFORMATION,
  DOCUMENT_DETAIL_PROJECT_INFORMATION,
  getDocumentDetailContextMenuItems,
} from "./documentDetail";

interface DocumentDetailEditDocumentProps {
  document: Document;
  project?: Project;
}

export interface DocumentDetailActionItemClickProps {
  project: Project;
  document: Document;
  event: CustomEvent<ButtonDropdownProps.ItemClickDetails>;
}

export interface DocumentDetailProps {
  project?: Project;
  document?: Document;
  flashbarMessage?: NonNullable<FlashbarMessagesProps["messages"]>[number];
  onEditClick?: (props: DocumentDetailEditDocumentProps) => void;
  onActionItemClick?: (props: DocumentDetailActionItemClickProps) => void;
}

const DocumentDetail: FC<DocumentDetailProps> = ({
  project,
  document,
  flashbarMessage,
  onEditClick,
  onActionItemClick,
}) => {
  const { isEditor } = useContext(AuthContext);
  const { messages, addErrorMessage, removeAllMessages } = useFlashbarMessages(
    flashbarMessage ? [flashbarMessage] : [],
  );

  const { data: projectFromAPI, error: getProjectError } = useProject(
    project ? undefined : document?.projectId,
  );

  const renderedProject = project ?? projectFromAPI;

  const { data: metadataOptions } = useDocumentMetadataOptions();
  const marketplaceSequence = metadataOptions?.marketplaces?.map(
    ({ value }) => value,
  );

  useEffect(() => {
    if (!flashbarMessage) {
      removeAllMessages();
    }
  }, [flashbarMessage]);

  useEffect(() => {
    if (getProjectError) {
      addErrorMessage?.(getProjectError, GET_PROJECT_ERROR);
    }
  }, [getProjectError]);

  return (
    <div className={styles.documentDetail} data-testid="document-detail">
      <ContentLayout
        notifications={
          <FlashBarMessages
            data-testid="document-detail-flashbar-messages"
            data-document-detail-selector="flashbar-messages"
            messages={messages}
          />
        }
        header={
          <Header
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
                data-document-detail-selector="user-actions"
              >
                <ButtonDropdown
                  data-testid="document-detail-action-button"
                  items={getDocumentDetailContextMenuItems({ isEditor })}
                  onItemClick={(event) =>
                    document &&
                    renderedProject &&
                    onActionItemClick?.({
                      project: renderedProject,
                      document,
                      event,
                    })
                  }
                >
                  Actions
                </ButtonDropdown>
                <Button
                  data-testid="document-detail-edit-button"
                  data-roles={`${UserRole.ADMIN} ${UserRole.AUTHOR}`}
                  onClick={() =>
                    document &&
                    onEditClick?.({ document, project: renderedProject })
                  }
                >
                  Edit
                </Button>
              </SpaceBetween>
            }
          ></Header>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <KeyValuePairCard
            data-testid="document-detail-project-information"
            columns={1}
            items={
              renderedProject
                ? DOCUMENT_DETAIL_PROJECT_INFORMATION(renderedProject)
                : []
            }
            headerTitle="Project information"
            headerVariant="h3"
          />
          <Container
            data-testid="document-detail-document-summary"
            header={<Header variant="h3">Document summary</Header>}
          >
            {document?.documentSummary}
          </Container>
          <KeyValuePairCard
            data-testid="document-detail-general-information"
            columns={2}
            items={
              document
                ? DOCUMENT_DETAIL_GENERAL_INFORMATION(
                  document,
                  marketplaceSequence,
                )
                : []
            }
            headerTitle="General information"
            headerVariant="h3"
          />
          <KeyValuePairCard
            data-testid="document-detail-additional-information"
            columns={2}
            items={
              document ? DOCUMENT_DETAIL_ADDITIONAL_INFORMATION(document) : []
            }
            headerTitle="Additional information"
            headerVariant="h3"
          />
        </SpaceBetween>
      </ContentLayout>
    </div>
  );
};

export default DocumentDetail;
