import { TableProps } from "@cloudscape-design/components/table";
import { useLocalStoragePreferences } from "../../hooks";

export function useTableColumnWidth(key: string) {
  const { preferences, setPreferences } =
    useLocalStoragePreferences<TableProps.ColumnWidthsChangeDetail>(key);

  const onColumnWidthsChange: TableProps["onColumnWidthsChange"] = ({
    detail,
  }) => {
    setPreferences(detail);
  };

  return {
    widths: preferences?.widths,
    onColumnWidthsChange,
    /**
     * Use this key to force a re-render of the table when column width preference is loaded
     */
    tableKeyForWidths: preferences?.widths === undefined ? undefined : key,
  };
}
