import axios from "axios";
import { WebsiteSettings } from "../interfaces";

let settings: WebsiteSettings;

export async function getSettings(): Promise<WebsiteSettings> {
  if (!settings) {
    const response = await axios.get<WebsiteSettings>("settings.json", {
      baseURL: window.location.origin,
      headers: {
        Accept: "application/json",
      },
    });
    settings = response.data;
  }
  return settings;
}
