import React, { FC } from "react";

import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import styles from "./CustomFormField.module.css";

interface CustomFormFieldProps extends FormFieldProps {
  required?: boolean;
}

const CustomFormField: FC<CustomFormFieldProps> = ({
  label,
  required = false,
  ...props
}) => {
  return (
    <FormField
      label={
        <>
          {label}
          {required ? (
            ""
          ) : (
            <span className={styles.optional}>
              <i>{label ? " - " : ""}optional</i>
            </span>
          )}
        </>
      }
      i18nStrings={{ errorIconAriaLabel: "Error" }}
      stretch={true}
      {...props}
    />
  );
};

export default CustomFormField;
