import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import Input, { InputProps } from "@cloudscape-design/components/input";
import { useInput } from "../../hooks";
import CustomFormField from "./CustomFormField";
import { BaseInputProps, BaseInputRefAttributes } from ".";

export interface TextInputProps<K extends string = string>
  extends BaseInputProps<string, K>,
    Pick<InputProps, "inputMode"> {}

function TextInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    constraintText,
    ...props
  }: TextInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<string>({
      validate,
      initialState: defaultValue,
    });

  const inputRef = useRef<InputProps.Ref>(null);

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
    };
  }, [handleBlur, inputRef]);

  function handleChange(value: string) {
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
  }

  return (
    <CustomFormField
      label={label}
      required={required}
      description={description}
      errorText={errorText}
      constraintText={constraintText}
    >
      <Input
        value={value}
        ariaRequired={required}
        placeholder={placeholder}
        onChange={({ detail: { value } }) => {
          handleChange(value);
        }}
        onBlur={handleBlur}
        disabled={disabled}
        name={fieldId}
        ref={inputRef}
        {...props}
      />
    </CustomFormField>
  );
}

export default forwardRef(TextInput);

export function TypedTextInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, TextInputProps<K>>(TextInput);
}
