import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Form from "@cloudscape-design/components/form";
import { useDebouncedCallback } from "use-debounce";
import {
  Document,
  DocumentMetadataOptionsForInputs,
  DocumentStatus,
  Project,
  ValueLabel,
} from "../../client/interfaces";
import {
  BaseInputRefAttributes,
  InputChangeDetail,
} from "../../components/inputs";
import {
  getObjectKey,
  getUnixTimestampDurationBeforeNow,
  objectMap,
  toLocalDate,
  toUnixTimestamp,
} from "../../helpers";
import {
  useGetDocumentTitles,
  useCreateDocument,
  useCreateProject,
  useDocumentMetadataOptions,
  useFlashbarMessages,
  useProjects,
  useUpdateDocument,
  useUploadDocumentFile,
} from "../../hooks";
import CustomModal from "../../components/common/CustomModal";
import { AuthContext } from "../../context/AuthContext";
import FlashBarMessages from "../../components/common/FlashbarMessages";
import {
  CREATE_PROJECT_ERROR,
  GET_DOCUMENT_METADATA_OPTION_ERROR,
  GET_PROJECTS_ERROR,
  UPDATE_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_FILE_ERROR,
} from "../../data/constants/errorMessage";
import {
  DEFAULT_DEBOUNCE_DELAY_MILLIS,
  MINIMUM_DOCUMENT_TITLE_LENGTH,
} from "../../data/constants/common";
import {
  DocumentMetadataName,
  REPLACE_DOCUMENT,
  REPLACE_DOCUMENT_CONTENT,
} from "../common/documentConstant";
import { useDocumentYearQuarterMonthOptions } from "../common/documentOption";
import {
  DOCUMENT_FORM_GENERAL_INFORMATION,
  DOCUMENT_FORM_INPUTS,
  DOCUMENT_FORM_ADDITIONAL_INFORMATION,
  DocumentFormInputsProps,
  DocumentFormFieldId,
  DocumentInputValue,
  DocumentFormBoolean,
  CREATE_NEW_PROJECT_INPUT,
  DOCUMENT_FORM_PROJECT_INFORMATION,
  getInitialDocumentFormValues,
  DocumentFormSubmitAction,
  documentInputValueToCreateDocumentCommandInput,
} from "./documentForm";
import styles from "./DocumentForm.module.scss";

interface DocumentFormSubmitDetail {
  document: Document;
  noChanges?: boolean;
}

export interface DocumentFormProps {
  /**
   * Default project to populate the inputs.
   */
  project?: Project;
  /**
   * Default document to populate the inputs.
   */
  document?: Document;
  onCancel?: () => void;
  onSubmit?: (detail: DocumentFormSubmitDetail) => void;
  submitAction?: DocumentFormSubmitAction;
}

const DocumentForm: FC<DocumentFormProps> = ({
  project,
  document,
  onCancel,
  onSubmit,
  submitAction = DocumentFormSubmitAction.CREATE,
}) => {
  const { data: metadataOptions, error: getDocumentMetadataOptionError } =
    useDocumentMetadataOptions();
  const { data: projects, error: getProjectsError } = useProjects();
  const {
    trigger: createProject,
    isMutating: isCreatingProject,
    error: createProjectError,
  } = useCreateProject();
  const {
    trigger: uploadDocumentFile,
    isMutating: isUploadingDocumentFile,
    error: uploadDocumentFileError,
  } = useUploadDocumentFile();
  const {
    trigger: createDocument,
    isMutating: isCreatingDocument,
    error: createDocumentError,
  } = useCreateDocument();
  const {
    trigger: updateDocument,
    isMutating: isUpdatingDocument,
    error: updateDocumentError,
  } = useUpdateDocument(document?.documentId);

  const { alias } = useContext(AuthContext);

  const { messages, addErrorMessage } = useFlashbarMessages();

  const {
    defaultDocumentInputValue,
    defaultDocumentFormIsError,
    defaultDocumentFormIsModified,
  } = getInitialDocumentFormValues({
    documentFormSubmitAction: submitAction,
    document,
    project,
    authorAlias: alias,
  });

  const marketplaceSequence = metadataOptions?.marketplaces?.map(
    ({ value }) => value,
  );

  // Form input states
  const [
    documentMetadataOptionsForInputs,
    setDocumentMetadataOptionsForInputs,
  ] = useState<DocumentMetadataOptionsForInputs>();
  const [documentInputValue, setDocumentInputValue] =
    useState<DocumentInputValue>(defaultDocumentInputValue);
  const documentFormIsError = useRef<DocumentFormBoolean>(
    defaultDocumentFormIsError,
  );
  const documentFormIsModified = useRef<DocumentFormBoolean>(
    defaultDocumentFormIsModified,
  );

  // Check existing document title states
  const documentTitleExists = useRef(true);
  const [documentTitleToCheck, setDocumentTitleToCheck] = useState<
    string | undefined
  >(undefined);
  const { data: documentTitleResult, isLoading: isLoadingDocumentTitleResult } =
    useGetDocumentTitles(documentTitleToCheck);
  const debounceDocumentTitleToCheck = useDebouncedCallback(
    (value?: string) =>
      setDocumentTitleToCheck(
        (value ?? "").length >= MINIMUM_DOCUMENT_TITLE_LENGTH
          ? value
          : undefined,
      ),
    DEFAULT_DEBOUNCE_DELAY_MILLIS,
  );

  // New project title states
  const [createNewProjectTitleVisible, setCreateNewProjectTitleVisible] =
    useState(false);
  const [newProjectTitleOption, setNewProjectTitleOption] =
    useState<ValueLabel>();
  const newProjectTitle = useRef("");
  const isNewProjectTitleError = useRef(true);

  // Check existing project title states
  const projectTitleExists = useRef(true);
  const debounceProjectTitleExists = useDebouncedCallback((value: string) => {
    if (projects?.find((project) => project.projectTitle === value)) {
      projectTitleExists.current = true;
    } else {
      projectTitleExists.current = false;
    }
    const validationOutput =
      documentCreateNewProjectTitleInputProps.validate?.(value);
    isNewProjectTitleError.current = !(validationOutput?.isValid ?? true);

    if (value) {
      createNewProjectTitleRef.current?.validate();
    }
  }, DEFAULT_DEBOUNCE_DELAY_MILLIS);

  // Document year/quarter/month states
  const {
    setDocumentYearQuarterMonthFilteringText:
      setDocumentYearQuarterMonthFilteringText,
    documentYearQuarterMonthOptions,
  } = useDocumentYearQuarterMonthOptions();

  // Document replacement confirmation modal
  const [replaceDocumentModalVisible, setReplaceDocumentModalVisible] =
    useState(false);

  // use effects
  useEffect(() => {
    setDocumentMetadataOptionsForInputs({
      ...metadataOptions,
      projects: [
        ...(newProjectTitleOption ? [newProjectTitleOption] : []),
        ...(projects?.map((project) => ({
          value: project.projectId,
          label: project.projectTitle,
        })) ?? []),
      ],
      documentYearQuarterMonth: documentYearQuarterMonthOptions,
      documentTitles: documentTitleResult?.map((title) => ({ value: title })),
    });
  }, [
    metadataOptions,
    projects,
    newProjectTitleOption,
    documentTitleResult,
    documentYearQuarterMonthOptions,
  ]);

  useEffect(() => {
    const projectDescriptionForSelectedProjectTitle = projects?.find(
      (project) => project.projectId === documentInputValue.projectId,
    )?.projectDescription;
    if (projectDescriptionForSelectedProjectTitle) {
      refsMap.projectDescription?.current?.overrideValue?.(
        projectDescriptionForSelectedProjectTitle,
      );
    }
  }, [documentInputValue.projectId]);

  useEffect(() => {
    if (isLoadingDocumentTitleResult) {
      return;
    }

    documentTitleExists.current =
      (documentTitleResult ?? []).includes(documentInputValue.documentTitle) &&
      Boolean(documentFormIsModified.current.documentTitle);

    const validationOutput = documentFormInputs[
      DocumentMetadataName.DOCUMENT_TITLE
    ].props.validate?.(documentInputValue.documentTitle);

    documentFormIsError.current = {
      ...documentFormIsError.current,
      documentTitle: !(validationOutput?.isValid ?? true),
    };

    if (
      documentInputValue.documentTitle &&
      documentInputValue.documentTitle.length >= MINIMUM_DOCUMENT_TITLE_LENGTH
    ) {
      refsMap.documentTitle?.current?.validate();
    }
  }, [
    documentInputValue.documentTitle,
    documentTitleResult,
    isLoadingDocumentTitleResult,
  ]);

  useEffect(() => {
    if (
      documentFormIsModified.current.status &&
      documentInputValue.status === DocumentStatus.EXPIRED &&
      documentFormIsModified.current.documentExpirationDate
    ) {
      // if status is changed to EXPIRED and documentExpirationDate is modified
      // then reset documentExpirationDate
      refsMap.documentExpirationDate?.current?.reset();
    }
  }, [documentInputValue.status]);

  useEffect(() => {
    if (getProjectsError) {
      addErrorMessage(getProjectsError, GET_PROJECTS_ERROR);
    }
    if (getDocumentMetadataOptionError) {
      addErrorMessage(
        getDocumentMetadataOptionError,
        GET_DOCUMENT_METADATA_OPTION_ERROR,
      );
    }
    if (createProjectError) {
      addErrorMessage(createProjectError, CREATE_PROJECT_ERROR);
    }
    if (uploadDocumentFileError) {
      addErrorMessage(uploadDocumentFileError, UPLOAD_DOCUMENT_FILE_ERROR);
    }
    if (createDocumentError) {
      addErrorMessage(createDocumentError, CREATE_PROJECT_ERROR);
    }
    if (updateDocumentError) {
      addErrorMessage(updateDocumentError, UPDATE_DOCUMENT_ERROR);
    }
  }, [
    getDocumentMetadataOptionError,
    getProjectsError,
    createProjectError,
    uploadDocumentFileError,
    createDocumentError,
    updateDocumentError,
  ]);

  // use refs (for external control)
  const refsMap = objectMap(defaultDocumentInputValue, () =>
    useRef<BaseInputRefAttributes>(null),
  );
  const createNewProjectTitleRef = useRef<BaseInputRefAttributes>(null);

  // Handle new project title creation
  useEffect(() => {
    if (createNewProjectTitleVisible) {
      createNewProjectTitleRef.current?.focus();
    }
  }, [createNewProjectTitleVisible]);

  function handleNewProjectTitleChange(
    _: DocumentFormFieldId,
    detail: InputChangeDetail<DocumentInputValue["projectId"]>,
  ) {
    newProjectTitle.current = detail.value;
    isNewProjectTitleError.current = detail.isError;
    debounceProjectTitleExists(detail.value);
  }

  function handleCancelCreateNewProjectTitle() {
    createNewProjectTitleRef.current?.reset();
  }

  function handleCreateNewProjectTitle() {
    createNewProjectTitleRef.current?.validate();
    if (!isNewProjectTitleError.current) {
      refsMap.projectId?.current?.overrideValue?.(newProjectTitle.current);
      refsMap.projectDescription?.current?.overrideValue?.("");
      setNewProjectTitleOption({
        value: newProjectTitle.current,
        label: newProjectTitle.current,
      });
      setCreateNewProjectTitleVisible(false);
      createNewProjectTitleRef.current?.reset();
    }
  }

  // Handle form changes
  function handleChange(
    fieldId: DocumentFormFieldId,
    detail: InputChangeDetail<DocumentInputValue[DocumentFormFieldId]>,
  ) {
    setDocumentInputValue((prev) => ({
      ...prev,
      [fieldId]: detail.value,
    }));
    documentFormIsError.current = {
      ...documentFormIsError.current,
      [fieldId]: detail.isError,
    };
    documentFormIsModified.current = {
      ...documentFormIsModified.current,
      [fieldId]: detail.isModified,
    };

    if (fieldId === "documentTitle") {
      debounceDocumentTitleToCheck(detail.value as string);
    }
  }

  function handleCancel() {
    Object.values(refsMap).forEach((ref) => {
      ref.current?.reset();
    });
    onCancel?.();
  }

  function focusTopMostError() {
    for (const input of [
      ...projectInformationInputs,
      ...generalInformationInputs,
      ...additionalInformationInputs,
    ]) {
      if (documentFormIsError.current[input.props.fieldId]) {
        return refsMap[input.props.fieldId]?.current?.focus();
      }
    }
  }

  async function handleSubmit() {
    try {
      // Handle new project creation
      let projectId = documentInputValue.projectId; // projectId is either projectId for existing project or projectTitle for new project

      if (projectId === newProjectTitleOption?.value) {
        // if new project is selected
        const createdProject = await createProject({
          projectTitle: projectId,
          projectDescription: documentInputValue.projectDescription,
        });
        projectId = createdProject.projectId;
      }

      let newOrUpdatedDocument: Document | undefined = document;

      // Handle new document creation
      if (
        submitAction === DocumentFormSubmitAction.CREATE ||
        submitAction === DocumentFormSubmitAction.DUPLICATE
      ) {
        const documentObjectKey = getObjectKey(
          await uploadDocumentFile({ file: documentInputValue.fileName[0] }),
        );
        newOrUpdatedDocument = await createDocument(
          documentInputValueToCreateDocumentCommandInput({
            documentInputValue: { ...documentInputValue, projectId },
            documentObjectKey,
            marketplaceSequence,
          }),
        );
      }

      // Handle document update
      const isModified = Object.values(documentFormIsModified.current).some(
        (value) => value,
      );
      if (
        document &&
        submitAction === DocumentFormSubmitAction.EDIT &&
        isModified
      ) {
        const documentObjectKey = documentFormIsModified.current.fileName
          ? getObjectKey(
            await uploadDocumentFile({
              file: documentInputValue.fileName[0],
              documentLink: document.documentLink,
            }),
          )
          : document.documentLink;
        const fileSizeInBytesToPersist = documentFormIsModified.current.fileName
          ? undefined
          : document.fileSizeInBytes;
        // If status is modified, EXPIRED and expiration date is greater than today,
        // then set the expirate date to today
        const documentExpirationDate =
          documentFormIsModified.current.status &&
          documentInputValue.status === DocumentStatus.EXPIRED &&
          (toUnixTimestamp(documentInputValue.documentExpirationDate) ??
            Number.MAX_SAFE_INTEGER) > getUnixTimestampDurationBeforeNow()
            ? toLocalDate(getUnixTimestampDurationBeforeNow())
            : documentInputValue.documentExpirationDate;
        newOrUpdatedDocument = await updateDocument({
          ...documentInputValueToCreateDocumentCommandInput({
            documentInputValue: {
              ...documentInputValue,
              projectId,
              documentExpirationDate,
            },
            documentObjectKey,
            fileSizeInBytes: fileSizeInBytesToPersist,
            marketplaceSequence,
          }),
          documentId: document.documentId,
        });
      }

      if (!newOrUpdatedDocument) {
        throw new Error("Document create/update failed!");
      }

      onSubmit?.({
        document: newOrUpdatedDocument,
        noChanges: !isModified,
      });
    } catch (e) {
      // Error handled in flashbar messages
    }
  }

  function handleSubmitInterceptor() {
    Object.values(refsMap).forEach((ref) => {
      ref.current?.validate();
    });

    if (Object.values(documentFormIsError.current).some((value) => value)) {
      focusTopMostError();
    } else if (
      submitAction === DocumentFormSubmitAction.EDIT &&
      documentFormIsModified.current.fileName
    ) {
      setReplaceDocumentModalVisible(true);
    } else {
      handleSubmit();
    }
  }

  // Create all form input components and props
  // Include all external status control, validation, and form options here
  const documentFormInputsProps: DocumentFormInputsProps = {
    options: documentMetadataOptionsForInputs,
    defaultValue: defaultDocumentInputValue,
    readOnly: {
      projectDescription:
        Boolean(documentInputValue.projectId) &&
        documentInputValue.projectId !== newProjectTitleOption?.value,
    },
    disabled: {
      status:
        submitAction === DocumentFormSubmitAction.CREATE ||
        submitAction === DocumentFormSubmitAction.DUPLICATE,
      documentExpirationDate:
        documentInputValue.status === DocumentStatus.EXPIRED,
    },
    externalValidate: {
      projectId: () => !projectTitleExists.current,
      documentTitle: () => !documentTitleExists.current,
      documentExpirationDate: () =>
        documentInputValue.status === DocumentStatus.EXPIRED ||
        !documentFormIsModified.current.documentExpirationDate,
    },
    manualFilterOptions: {
      documentTitle: {
        loading: isLoadingDocumentTitleResult,
      },
      documentYearQuarterMonth: {
        onLoadItems: ({ detail: { filteringText } }) => {
          setDocumentYearQuarterMonthFilteringText(filteringText);
        },
      },
    },
  };
  const documentFormInputs = DOCUMENT_FORM_INPUTS(documentFormInputsProps);

  const {
    component: DocumentCreateNewProjectTitleInput,
    props: documentCreateNewProjectTitleInputProps,
  } = documentFormInputs[CREATE_NEW_PROJECT_INPUT];

  const projectInformationInputs = DOCUMENT_FORM_PROJECT_INFORMATION(
    documentFormInputsProps,
  );
  const generalInformationInputs = DOCUMENT_FORM_GENERAL_INFORMATION(
    documentFormInputsProps,
  );
  const additionalInformationInputs = DOCUMENT_FORM_ADDITIONAL_INFORMATION(
    documentFormInputsProps,
  );

  return (
    <div data-testid="document-form" className={styles.documentForm}>
      <Form
        header={
          <FlashBarMessages
            data-testid="document-form-flashbar-messages"
            data-document-form-selector="flashbar-messages"
            messages={messages}
          />
        }
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              data-testid="document-form-cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              data-testid="document-form-submit-button"
              onClick={handleSubmitInterceptor}
              loading={
                isCreatingProject ||
                isUploadingDocumentFile ||
                isCreatingDocument ||
                isUpdatingDocument
              }
            >
              {submitAction === DocumentFormSubmitAction.DUPLICATE
                ? DocumentFormSubmitAction.CREATE
                : submitAction}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Container
            data-testid="document-form-project-information"
            header={<Header variant="h3">Project information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {projectInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  <div key={fieldId}>
                    {
                      // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_PROJECT_INFORMATION`
                      <DocumentFormComponent
                        {...rest}
                        data-testid={`document-form-${fieldId}`}
                        fieldId={fieldId}
                        ref={refsMap[fieldId]}
                        onChange={handleChange}
                      />
                    }
                    {fieldId === "projectId" ? (
                      <Button
                        data-testid="document-form-create-new-project-button"
                        data-document-form-selector="create-project-button"
                        variant="normal"
                        onClick={() => setCreateNewProjectTitleVisible(true)}
                      >
                        Create new project
                      </Button>
                    ) : undefined}
                  </div>
                );
              })}
            </SpaceBetween>
          </Container>
          <Container
            data-testid="document-form-general-information"
            header={<Header variant="h3">General information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {generalInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_GENERAL_INFORMATION`
                  <DocumentFormComponent
                    {...rest}
                    data-testid={`document-form-${fieldId}`}
                    fieldId={fieldId}
                    key={fieldId}
                    ref={refsMap[fieldId]}
                    onChange={handleChange}
                  />
                );
              })}
            </SpaceBetween>
          </Container>
          <Container
            data-testid="document-form-additional-information"
            header={<Header variant="h3">Additional information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {additionalInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_ADDITIONAL_INFORMATION`
                  <DocumentFormComponent
                    {...rest}
                    data-testid={`document-form-${fieldId}`}
                    fieldId={fieldId}
                    key={fieldId}
                    ref={refsMap[fieldId]}
                    onChange={handleChange}
                  />
                );
              })}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
      <CustomModal
        data-testid="document-form-create-new-project-modal"
        visible={createNewProjectTitleVisible}
        setVisible={setCreateNewProjectTitleVisible}
        header={CREATE_NEW_PROJECT_INPUT}
        primaryActionButtonText="Ok"
        onPrimaryAction={handleCreateNewProjectTitle}
        onDismissOrCancel={handleCancelCreateNewProjectTitle}
      >
        <DocumentCreateNewProjectTitleInput
          {...documentCreateNewProjectTitleInputProps}
          data-testid="document-form-create-new-project-input"
          ref={createNewProjectTitleRef}
          onChange={handleNewProjectTitleChange}
        />
      </CustomModal>
      <CustomModal
        data-testid="document-form-replace-document-modal"
        visible={replaceDocumentModalVisible}
        setVisible={setReplaceDocumentModalVisible}
        header={REPLACE_DOCUMENT}
        primaryActionButtonText="Proceed"
        onPrimaryAction={() => {
          handleSubmit();
          setReplaceDocumentModalVisible(false);
        }}
      >
        {REPLACE_DOCUMENT_CONTENT(document?.fileName)}
      </CustomModal>
    </div>
  );
};

export default DocumentForm;
