import React from "react";
import { Container, Alert } from "@cloudscape-design/components";
import { isLocalEnvironment } from "../../data/constants/environment";

const FallbackComponent: React.FC<Error> = ({ name, message, stack }) => (
  <Container>
    <Alert type="error" header="An error occurred while rendering this view">
      <p>
        Try refreshing the page, and please contact our team if this issue
        persist.
      </p>

      <p>
        {name}: <i>{message}</i>
      </p>
      {isLocalEnvironment() && <pre>{stack}</pre>}
    </Alert>
  </Container>
);

export default FallbackComponent;
