import React, { useEffect, useState } from "react";
import Spinner from "@cloudscape-design/components/spinner";
import Alert from "@cloudscape-design/components/alert";
import { useDeleteProject, useDocuments } from "../../hooks";
import { MessagesContextType } from "../../context/MessagingContext";
import { DELETE_PROJECT_ERROR } from "../../data/constants/errorMessage";
import CustomModal from "../../components/common/CustomModal";
import { Project } from "../../client/interfaces";
import {
  DELETE_PROJECT,
  DELETE_PROJECT_ALERT,
  DELETE_PROJECT_CONTENT,
  PROJECT_DELETE_SUCCESS_MESSAGE,
} from "./projectConstant";

interface UseProjectDeleteProps {
  modalDataTestId?: string;
  onProjectChange?: (project: Project) => void;
  addMessage?: MessagesContextType["addMessage"];
  addErrorMessage?: MessagesContextType["addErrorMessage"];
}

export function useProjectDelete({
  modalDataTestId,
  onProjectChange,
  addMessage,
  addErrorMessage,
}: UseProjectDeleteProps) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const {
    trigger: deleteProjectApi,
    isMutating: isDeletingProject,
    error: deleteProjectError,
  } = useDeleteProject();
  const [projectToDelete, setProjectToDelete] = useState<Project>();
  const { data: documents, isLoading: isCheckingExistingDocuments } =
    useDocuments(projectToDelete?.projectId);

  useEffect(() => {
    if (deleteProjectError) {
      addErrorMessage?.(deleteProjectError, DELETE_PROJECT_ERROR);
    }
  }, [deleteProjectError]);

  function deleteProject({ project }: { project: Project }) {
    setProjectToDelete(project);
    setDeleteModalVisible(true);
  }

  async function confirmDeleteProject() {
    if (projectToDelete) {
      try {
        await deleteProjectApi(projectToDelete);
        setDeleteModalVisible(false);
        onProjectChange?.(projectToDelete);
        addMessage?.(PROJECT_DELETE_SUCCESS_MESSAGE);
        setProjectToDelete(undefined);
      } catch {
        // Error handled in flashbar messages
      }
    }
  }

  const projectModalContent = documents?.length ? (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      header={DELETE_PROJECT_ALERT.header}
    >
      {DELETE_PROJECT_ALERT.content}
    </Alert>
  ) : (
    DELETE_PROJECT_CONTENT
  );

  const projectDeleteConfirmationModal = (
    <CustomModal
      data-testid={modalDataTestId}
      visible={deleteModalVisible}
      setVisible={setDeleteModalVisible}
      header={DELETE_PROJECT}
      primaryActionButtonText="Delete"
      onPrimaryAction={confirmDeleteProject}
      primaryActionLoading={isDeletingProject}
      primaryActionDisabled={
        isCheckingExistingDocuments || Boolean(documents?.length)
      }
    >
      {isCheckingExistingDocuments ? <Spinner /> : projectModalContent}
    </CustomModal>
  );

  return {
    projectDeleteConfirmationModal,
    deleteProject,
  };
}
