import React, { useEffect, useRef, useState } from "react";
import { useDeleteDocument } from "../../hooks";
import { MessagesContextType } from "../../context/MessagingContext";
import { DELETE_DOCUMENT_ERROR } from "../../data/constants/errorMessage";
import CustomModal from "../../components/common/CustomModal";
import { Document } from "../../client/interfaces";
import {
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_CONTENT,
  DOCUMENT_DELETE_SUCCESS_MESSAGE,
} from "./documentConstant";

interface UseDocumentDeleteProps {
  modalDataTestId?: string;
  onDocumentChange?: (document: Document) => void;
  addMessage?: MessagesContextType["addMessage"];
  addErrorMessage?: MessagesContextType["addErrorMessage"];
}

export function useDocumentDelete({
  modalDataTestId,
  onDocumentChange,
  addMessage,
  addErrorMessage,
}: UseDocumentDeleteProps = {}) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const {
    trigger: deleteDocumentApi,
    isMutating: isDeletingDocument,
    error: deleteDocumentError,
  } = useDeleteDocument();
  const documentToDelete = useRef<Document | null>(null);

  useEffect(() => {
    if (deleteDocumentError) {
      addErrorMessage?.(deleteDocumentError, DELETE_DOCUMENT_ERROR);
    }
  }, [deleteDocumentError]);

  function deleteDocument(document: Document) {
    documentToDelete.current = document;
    setDeleteModalVisible(true);
  }

  async function confirmDeleteDocument() {
    if (documentToDelete.current) {
      try {
        await deleteDocumentApi(documentToDelete.current);
        setDeleteModalVisible(false);
        onDocumentChange?.(documentToDelete.current);
        addMessage?.(DOCUMENT_DELETE_SUCCESS_MESSAGE);
        documentToDelete.current = null;
      } catch {
        // Error handled in flashbar messages
      }
    }
  }
  const documentDeleteConfirmationModal = (
    <CustomModal
      data-testid={modalDataTestId}
      visible={deleteModalVisible}
      setVisible={setDeleteModalVisible}
      header={DELETE_DOCUMENT}
      primaryActionButtonText="Delete"
      onPrimaryAction={confirmDeleteDocument}
      primaryActionLoading={isDeletingDocument}
    >
      {DELETE_DOCUMENT_CONTENT}
    </CustomModal>
  );

  return {
    documentDeleteConfirmationModal,
    deleteDocument,
  };
}
