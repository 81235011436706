
    import './styles.scoped.css';
    export default {
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_1ydka_141",
  "trigger": "awsui_trigger_lpshu_1ydka_141",
  "selected": "awsui_selected_lpshu_1ydka_236",
  "badge": "awsui_badge_lpshu_1ydka_264",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_1ydka_268",
  "dot": "awsui_dot_lpshu_1ydka_276"
};
  