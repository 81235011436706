import React, { FC } from "react";
import { Link } from "react-router-dom";
import Header from "@cloudscape-design/components/header";
import ServerClientHybridCollectionTable from "../../../components/table/ServerClientHybridCollectionTable";
import { Document, Project } from "../../../client/interfaces";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../../common/documentConstant";
import { toLocalDate } from "../../../helpers";
import { DOCUMENT_DETAIL_LINK } from "../../../data/constants/common";

interface ProjectDetailDocumentsTableProps {
  project: Project;
  documents?: Document[];
  isLoading?: boolean;
}

const ProjectDetailDocumentsTable: FC<ProjectDetailDocumentsTableProps> = ({
  project,
  documents,
  isLoading,
}) => {
  return (
    <div data-testid="project-detail-document-table">
      <ServerClientHybridCollectionTable
        contentDensity="compact"
        header={
          <Header headingTagOverride="h3" variant="h3">
            Documents
          </Header>
        }
        sortingColumn={{
          sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.UPLOAD_DATE],
        }}
        sortingDescending={true}
        withClientSidePagination={true}
        withClientSideSorting={true}
        items={documents ?? []}
        loading={isLoading}
        columnDefinitions={[
          {
            id: DocumentMetadataName.DOCUMENT_TITLE,
            header: DocumentMetadataName.DOCUMENT_TITLE,
            cell: (e) => (
              <Link
                to={DOCUMENT_DETAIL_LINK(project.projectTitle, e.documentTitle)}
              >
                {e.documentTitle}
              </Link>
            ),
            isRowHeader: true,
          },
          {
            id: DocumentMetadataName.UPLOAD_DATE,
            header: DocumentMetadataName.UPLOAD_DATE,
            cell: (e) => toLocalDate(e.lastUploadDate),
            width: "150px",
          },
        ]}
        empty="No documents"
      />
    </div>
  );
};

export default ProjectDetailDocumentsTable;
