import React, { FC } from "react";
import Modal, { ModalProps } from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";

interface CustomModalProps
  extends Pick<ModalProps, "children" | "header" | "visible"> {
  primaryActionButtonText?: string;
  onPrimaryAction?: () => void;
  onDismissOrCancel?: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  primaryActionLoading?: boolean;
  primaryActionDisabled?: boolean;
}

const CustomModal: FC<CustomModalProps> = ({
  children,
  primaryActionButtonText = "Ok",
  onPrimaryAction,
  onDismissOrCancel,
  setVisible,
  primaryActionLoading,
  primaryActionDisabled,
  ...props
}) => {
  const closeModal = () => setVisible(false);
  return (
    <Modal
      data-testid="custom-modal"
      onDismiss={() => {
        closeModal();
        onDismissOrCancel?.();
      }}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                closeModal();
                onDismissOrCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onPrimaryAction}
              loading={primaryActionLoading}
              disabled={primaryActionDisabled}
            >
              {primaryActionButtonText}
            </Button>
          </SpaceBetween>
        </Box>
      }
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
