
    import './styles.scoped.css';
    export default {
  "universal-toolbar": "awsui_universal-toolbar_1kzri_1v9zp_141",
  "disable-body-scroll": "awsui_disable-body-scroll_1kzri_1v9zp_162",
  "toolbar-hidden": "awsui_toolbar-hidden_1kzri_1v9zp_165",
  "toolbar-container": "awsui_toolbar-container_1kzri_1v9zp_168",
  "universal-toolbar-nav": "awsui_universal-toolbar-nav_1kzri_1v9zp_177",
  "universal-toolbar-breadcrumbs": "awsui_universal-toolbar-breadcrumbs_1kzri_1v9zp_181",
  "universal-toolbar-drawers": "awsui_universal-toolbar-drawers_1kzri_1v9zp_186",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1kzri_1v9zp_193",
  "drawers-trigger-content": "awsui_drawers-trigger-content_1kzri_1v9zp_237",
  "has-multiple-triggers": "awsui_has-multiple-triggers_1kzri_1v9zp_244",
  "has-open-drawer": "awsui_has-open-drawer_1kzri_1v9zp_244",
  "drawers-trigger": "awsui_drawers-trigger_1kzri_1v9zp_237"
};
  