import { CreateDocumentInput, Project } from "../../../client/interfaces";

export enum DocumentFormSubmitAction {
  CREATE = "Create",
  EDIT = "Update",
  DUPLICATE = "Duplicate",
}

export type DocumentFormFieldId = keyof DocumentInput;

export type DocumentInput = Omit<
  CreateDocumentInput,
  | "documentLink"
  | "documentType"
  | "fileSizeInBytes"
  | "lastUpdatedBy" // Dummy value
  | "lastUpdateDate" // Dummy value
  | "lastUploadDate" // Dummy value
  | "documentId" // Dummy value
> &
  Pick<Project, "projectDescription">;

export type DocumentInputValue = Required<{
  [K in keyof Omit<DocumentInput, "fileName">]: DocumentInput[K] extends
    | string[]
    | undefined
    ? string[]
    : string;
}> & { fileName: File[]; fileSizeInBytes?: number };

export type DocumentFormBoolean = { [k in DocumentFormFieldId]?: boolean };
