import React, { FC } from "react";
import Flashbar, {
  FlashbarProps,
} from "@cloudscape-design/components/flashbar";

export interface FlashbarMessagesProps {
  messages?: FlashbarProps.MessageDefinition[];
}

const FlashBarMessages: FC<FlashbarMessagesProps> = ({
  messages,
  ...props
}) => {
  return (
    <div data-testid="flashbar-messages" {...props}>
      <Flashbar
        items={messages ?? []}
        data-testid="flashbar"
        stackItems={(messages ?? [])?.length > 3}
      />
    </div>
  );
};

export default FlashBarMessages;
