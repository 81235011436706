import React, { FC } from "react";
import Link, { LinkProps } from "@cloudscape-design/components/link";
import styles from "./GreyLink.module.scss";

/**
 * Renders the Cloudscape `Link` component with dark grey color.
 */
const GreyLink: FC<LinkProps> = ({ children, ...props }) => {
  return (
    <div className={styles.greyLink}>
      <Link data-testid="grey-link" data-grey-link-selector="true" {...props}>
        {children}
      </Link>
    </div>
  );
};

export default GreyLink;
