
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_19bso_gdeu9_149",
  "token": "awsui_token_19bso_gdeu9_154",
  "show-operation": "awsui_show-operation_19bso_gdeu9_169",
  "select": "awsui_select_19bso_gdeu9_175",
  "token-content": "awsui_token-content_19bso_gdeu9_179",
  "dismiss-button": "awsui_dismiss-button_19bso_gdeu9_184",
  "token-disabled": "awsui_token-disabled_19bso_gdeu9_226"
};
  