import React, { FC, ReactElement } from "react";
import { Routes, Route, RouteProps, Navigate } from "react-router-dom";
import DocumentDashboard from "../../views/document/DocumentDashboard";
import ProjectDashboard from "../../views/project/ProjectDashboard";
import DocumentDownload from "../../views/document/DocumentDownload";
import { RoutePath } from "./common";

const routes: RouteProps[] = [
  /** HOME */
  {
    path: "/",
    element: <Navigate replace to={RoutePath.ALL_PROJECTS} />,
  },
  {
    path: RoutePath.HOME,
    element: <Navigate replace to={RoutePath.ALL_PROJECTS} />,
  },
  /** PROJECTS */
  {
    path: RoutePath.ALL_PROJECTS,
    element: <ProjectDashboard />,
  },
  /** DOCUMENT */
  {
    path: RoutePath.PROJECT,
    element: <DocumentDashboard />,
  },
  {
    path: RoutePath.DOCUMENT_DOWNLOAD,
    element: <DocumentDownload />,
  },
  /** DEFAULT */
  {
    path: "*",
    element: <Navigate replace to={RoutePath.ALL_PROJECTS} />,
  },
];

const AppRoutes: FC = (): ReactElement => {
  return (
    <Routes>
      {routes.map((routeProps: RouteProps, index: number) => (
        <Route {...routeProps} key={index} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
