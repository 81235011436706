import { PropertyFilterOperatorFormProps } from "@cloudscape-design/collection-hooks";
import React from "react";
import DatePickerInput from "../inputs/DatePickerInput";
import { InputChangeDetail } from "../inputs";
import { toLocalDate, toUnixTimestamp } from "../../helpers";

type DatePropertyFilterForm = PropertyFilterOperatorFormProps<string>;

export default function TablePropertyDateFilterForm({
  value,
  operator,
  onChange,
}: DatePropertyFilterForm) {
  // Using the most reasonable default time per operator.
  const defaultTime =
    operator === ">" || operator === ">=" ? "T00:00:00" : "T23:59:59";

  function handleOnChange(_: string, detail: InputChangeDetail<string>) {
    onChange(toUnixTimestamp(detail.value + defaultTime)?.toString() ?? "");
  }

  return (
    <DatePickerInput
      defaultValue={value ? toLocalDate(parseInt(value)) : ""}
      required
      expandToViewport
      fieldId="datePropertyFilter"
      placeholder="YYYY/MM/DD"
      onChange={handleOnChange}
    />
  );
}
