import axios, { AxiosResponse } from "axios";
import { FILE_UPLOAD_TIMEOUT } from "../data/constants/common";

export async function uploadFile(
  presignedUrl: string,
  file: File,
  headers: { [key: string]: string } = {},
  timeoutInMillis: number = FILE_UPLOAD_TIMEOUT,
): Promise<AxiosResponse<void>> {
  const response = await axios.put(presignedUrl, file, {
    headers: {
      "Content-Type": "application/octet-stream",
      ...headers,
    },
    timeout: timeoutInMillis,
  });
  return response;
}

export function getObjectKey(presignedUrl: string): string {
  const url = new URL(presignedUrl);
  return url.pathname.substring(1); // Remove leading "/"
}
