import useSWR from "swr";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";

import {
  ROLE_ATTRIBUTE_NAME,
  UserIdentity,
  UserRole,
} from "../client/interfaces";
import { extractAlias } from "../helpers";

/**
 * Obtain authenticated user identity with Amplify.
 *
 * @throws {AuthError}
 * Exception is thrown when user is not authenticated.
 * @throws {SyntaxError}
 * Exception is thrown when there are errors parsing user attributes.
 * @throws {TypeError}
 * Exception is thrown when user attributes are invalid.
 */
export function useUserIdentity(settings: unknown) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (settings ? "getUserIdentity" : false),
    async () => {
      await getCurrentUser();
      const session = await fetchAuthSession();
      const role: UserRole[] = JSON.parse(
        (session.tokens?.idToken?.payload?.[ROLE_ATTRIBUTE_NAME] as
          | string
          | undefined) ?? "[]",
      );
      if (!Array.isArray(role)) {
        throw new TypeError("User role attribute is invalid");
      }
      const alias = extractAlias(
        session.tokens?.idToken?.payload?.email as string,
      );
      return {
        role: role.filter((roleItem) =>
          Object.values(UserRole).includes(roleItem),
        ),
        email: session.tokens?.idToken?.payload?.email,
        alias,
        givenName: session.tokens?.idToken?.payload?.given_name,
        familyName: session.tokens?.idToken?.payload?.family_name,
      } as UserIdentity;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
