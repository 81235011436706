import { useRef, useState } from "react";
import { Project } from "../../../client/interfaces";
import { InputChangeDetail } from "../../../components/inputs";
import { getInitialProjectFormValues } from "./projectFormInitialValues";
import {
  ProjectFormBoolean,
  ProjectFormFieldId,
  ProjectFormSubmitAction,
  ProjectInputValue,
} from "./projectFormTypes";

interface UseProjectFormChangeProps {
  project?: Project;
  submitAction: ProjectFormSubmitAction;
  onProjectTitleChange?: (projectTitle: string) => void;
}

export function useProjectFormChange({
  project,
  submitAction,
  onProjectTitleChange,
}: UseProjectFormChangeProps) {
  const {
    defaultProjectInputValue,
    defaultProjectFormIsError,
    defaultProjectFormIsModified,
  } = getInitialProjectFormValues({
    projectFormSubmitAction: submitAction,
    project,
  });
  const [projectInputValue, setProjectInputValue] = useState<ProjectInputValue>(
    defaultProjectInputValue,
  );
  const projectFormIsError = useRef<ProjectFormBoolean>(
    defaultProjectFormIsError,
  );
  const projectFormIsModified = useRef<ProjectFormBoolean>(
    defaultProjectFormIsModified,
  );

  // Handle form changes
  function handleChange(
    fieldId: ProjectFormFieldId,
    detail: InputChangeDetail<ProjectInputValue[ProjectFormFieldId]>,
  ) {
    setProjectInputValue((prev) => ({
      ...prev,
      [fieldId]: detail.value,
    }));
    projectFormIsError.current = {
      ...projectFormIsError.current,
      [fieldId]: detail.isError,
    };
    projectFormIsModified.current = {
      ...projectFormIsModified.current,
      [fieldId]: detail.isModified,
    };

    if (fieldId === "projectTitle") {
      onProjectTitleChange?.(detail.value as string);
    }
  }

  return {
    handleChange,
    defaultProjectInputValue,
    projectInputValue,
    projectFormIsError,
    projectFormIsModified,
  };
}
