
    import './styles.scoped.css';
    export default {
  "expandable-toggle-wrapper": "awsui_expandable-toggle-wrapper_c6tup_1yugn_141",
  "body-cell": "awsui_body-cell_c6tup_1yugn_148",
  "body-cell-content": "awsui_body-cell-content_c6tup_1yugn_156",
  "expandable-level-0": "awsui_expandable-level-0_c6tup_1yugn_162",
  "expandable-level-1": "awsui_expandable-level-1_c6tup_1yugn_168",
  "expandable-level-2": "awsui_expandable-level-2_c6tup_1yugn_174",
  "expandable-level-3": "awsui_expandable-level-3_c6tup_1yugn_180",
  "expandable-level-4": "awsui_expandable-level-4_c6tup_1yugn_186",
  "expandable-level-5": "awsui_expandable-level-5_c6tup_1yugn_192",
  "expandable-level-6": "awsui_expandable-level-6_c6tup_1yugn_198",
  "expandable-level-7": "awsui_expandable-level-7_c6tup_1yugn_204",
  "expandable-level-8": "awsui_expandable-level-8_c6tup_1yugn_210",
  "expandable-level-9": "awsui_expandable-level-9_c6tup_1yugn_216",
  "expandable-level-next": "awsui_expandable-level-next_c6tup_1yugn_222",
  "body-cell-wrap": "awsui_body-cell-wrap_c6tup_1yugn_240",
  "is-visual-refresh": "awsui_is-visual-refresh_c6tup_1yugn_254",
  "has-striped-rows": "awsui_has-striped-rows_c6tup_1yugn_266",
  "body-cell-edit-active": "awsui_body-cell-edit-active_c6tup_1yugn_338",
  "body-cell-interactive": "awsui_body-cell-interactive_c6tup_1yugn_338",
  "body-cell-editable": "awsui_body-cell-editable_c6tup_1yugn_338",
  "has-striped-rows-sticky-cell-pad-inline-start": "awsui_has-striped-rows-sticky-cell-pad-inline-start_c6tup_1yugn_482",
  "has-selection": "awsui_has-selection_c6tup_1yugn_554",
  "body-cell-first-row": "awsui_body-cell-first-row_c6tup_1yugn_633",
  "body-cell-last-row": "awsui_body-cell-last-row_c6tup_1yugn_636",
  "body-cell-selected": "awsui_body-cell-selected_c6tup_1yugn_636",
  "has-footer": "awsui_has-footer_c6tup_1yugn_636",
  "body-cell-shaded": "awsui_body-cell-shaded_c6tup_1yugn_646",
  "sticky-cell": "awsui_sticky-cell_c6tup_1yugn_674",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_c6tup_1yugn_682",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_c6tup_1yugn_768",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_c6tup_1yugn_777",
  "body-cell-next-selected": "awsui_body-cell-next-selected_c6tup_1yugn_808",
  "body-cell-prev-selected": "awsui_body-cell-prev-selected_c6tup_1yugn_814",
  "body-cell-editor-wrapper": "awsui_body-cell-editor-wrapper_c6tup_1yugn_838",
  "body-cell-success": "awsui_body-cell-success_c6tup_1yugn_843",
  "body-cell-editor": "awsui_body-cell-editor_c6tup_1yugn_838",
  "body-cell-editor-disabled": "awsui_body-cell-editor-disabled_c6tup_1yugn_868",
  "body-cell-editor-form": "awsui_body-cell-editor-form_c6tup_1yugn_877",
  "body-cell-editor-row": "awsui_body-cell-editor-row_c6tup_1yugn_887",
  "body-cell-editor-controls": "awsui_body-cell-editor-controls_c6tup_1yugn_897",
  "body-cell-editor-row-editor": "awsui_body-cell-editor-row-editor_c6tup_1yugn_900",
  "body-cell-expandable": "awsui_body-cell-expandable_c6tup_1yugn_903",
  "resizable-columns": "awsui_resizable-columns_c6tup_1yugn_924",
  "expandable-cell-content": "awsui_expandable-cell-content_c6tup_1yugn_927",
  "body-cell-has-success": "awsui_body-cell-has-success_c6tup_1yugn_996",
  "body-cell-edit-disabled-popover": "awsui_body-cell-edit-disabled-popover_c6tup_1yugn_996"
};
  