import React, { FC } from "react";
import Pagination, {
  PaginationProps,
} from "@cloudscape-design/components/pagination";

export interface TablePaginationProps extends PaginationProps {
  setCurrentPageIndex: (page: number) => void;
}

const TablePagination: FC<TablePaginationProps> = ({
  currentPageIndex,
  setCurrentPageIndex,
  pagesCount,
  ...props
}) => {
  return (
    <Pagination
      data-testid="table-pagination"
      currentPageIndex={currentPageIndex}
      pagesCount={pagesCount}
      ariaLabels={{
        nextPageLabel: "Next page",
        previousPageLabel: "Previous page",
        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
      }}
      onNextPageClick={() => setCurrentPageIndex(currentPageIndex + 1)}
      onPreviousPageClick={() => setCurrentPageIndex(currentPageIndex - 1)}
      onChange={({ detail }) => {
        setCurrentPageIndex(detail.currentPageIndex);
      }}
      {...props}
    />
  );
};

export default TablePagination;
