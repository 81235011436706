import { CollectionPreferencesProps } from "@cloudscape-design/components/collection-preferences";
import { TableProps } from "@cloudscape-design/components/table";
import {
  PropertyFilterOption,
  PropertyFilterQuery,
} from "@cloudscape-design/collection-hooks";
import { Document } from "../../../client/interfaces";
import { DEFAULT_PAGE_SIZE } from "../../../data/constants/common";
import {
  isCaseInsensitivePartialStringMatch,
  objectMap,
} from "../../../helpers";
import { DocumentMetadataName } from "../../common/documentConstant";

const DOCUMENT_TABLE_COLUMN_DISPLAY: TableProps["columnDisplay"] = [
  {
    id: DocumentMetadataName.DOCUMENT_TITLE,
    visible: true,
  },
  {
    id: DocumentMetadataName.DOCUMENT_TYPE,
    visible: false,
  },
  {
    id: DocumentMetadataName.MARKETPLACES,
    visible: true,
  },
  {
    id: DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    visible: false,
  },
  {
    id: DocumentMetadataName.FILENAME,
    visible: false,
  },
  {
    id: DocumentMetadataName.AUTHOR,
    visible: true,
  },
  {
    id: DocumentMetadataName.UPLOAD_DATE,
    visible: true,
  },
  {
    id: DocumentMetadataName.DOCUMENT_LINK,
    visible: true,
  },
  {
    id: DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    visible: true,
  },
  {
    id: DocumentMetadataName.BIG_ROCKS,
    visible: false,
  },
  {
    id: DocumentMetadataName.STATUS,
    visible: true,
  },
  {
    id: DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    visible: false,
  },
  {
    id: DocumentMetadataName.DOCUMENT_CLASSIFICATION,
    visible: false,
  },
];

export const DOCUMENT_TABLE_PREFERENCES: CollectionPreferencesProps["preferences"] =
  {
    contentDensity: "comfortable",
    contentDisplay: DOCUMENT_TABLE_COLUMN_DISPLAY,
    pageSize: DEFAULT_PAGE_SIZE,
    stripedRows: false,
    wrapLines: false,
  };

export const DOCUMENT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS: ReadonlyArray<CollectionPreferencesProps.ContentDisplayOption> =
  DOCUMENT_TABLE_COLUMN_DISPLAY.map((item) => {
    if (item.id === DocumentMetadataName.DOCUMENT_TITLE) {
      return { id: item.id, label: item.id, alwaysVisible: true };
    }
    return { id: item.id, label: item.id };
  });

export const TABLE_FILTERING_OPTIONS_FROM_DOCUMENTS = (
  documents: Document[],
): PropertyFilterOption[] => {
  const documentFilterFields = [
    "documentTitle",
    "documentType",
    "marketplaces",
    "documentYearQuarterMonth",
    "fileName",
    "author",
    "primarySubjectArea",
    "additionalSubjectAreas",
    "bigRocks",
    "status",
    "documentContentTypes",
    "documentClassification",
  ] as const;

  const emptyOptions = documentFilterFields.reduce(
    (previous, current) => ({
      ...previous,
      [current]: [],
    }),
    {},
  ) as { [k in (typeof documentFilterFields)[number]]: string[] };

  const allValues = documents.reduce(
    (previous, current) => ({
      documentTitle: [...previous.documentTitle, current.documentTitle],
      documentType: [...previous.documentType, current.documentType],
      marketplaces: [...previous.marketplaces, ...current.marketplaces],
      documentYearQuarterMonth: [
        ...previous.documentYearQuarterMonth,
        ...current.documentYearQuarterMonth,
      ],
      fileName: [...previous.fileName, current.fileName],
      author: [...previous.author, current.author],
      primarySubjectArea: [
        ...previous.primarySubjectArea,
        current.primarySubjectArea,
      ],
      additionalSubjectAreas: [
        ...previous.additionalSubjectAreas,
        ...(current.additionalSubjectAreas ?? []),
      ],
      status: [...previous.status, current.status],
      documentContentTypes: [
        ...previous.documentContentTypes,
        ...(current.documentContentTypes ?? []),
      ],
      documentClassification: [
        ...previous.documentClassification,
        current.documentClassification,
      ],
      bigRocks: [...(previous.bigRocks ?? []), ...(current.bigRocks ?? [])],
    }),
    emptyOptions,
  );

  const uniqueValues = objectMap(allValues, (value) =>
    Array.from(new Set(value)),
  );

  const propertyFilterOptions: PropertyFilterOption[] = [];
  Object.entries(uniqueValues).forEach(([key, values]) => {
    propertyFilterOptions.push(
      ...(values ?? []).map((value) => ({
        propertyKey: key,
        value,
      })),
    );
  });

  return propertyFilterOptions;
};

export const TABLE_FILTERING_FUNCTION = (
  document: Document,
  query: PropertyFilterQuery,
): boolean => {
  const { tokens, operation } = query;

  // operator is ">=" or "<=" for numbers, ":" or "!:" for free text search, and ":" or "=" for all others
  const itemFilterFunction = ({
    value,
    propertyKey,
    operator,
  }: PropertyFilterQuery["tokens"][number]) => {
    if (!propertyKey) {
      const isMatch = isCaseInsensitivePartialStringMatch(
        document.documentTitle,
        value,
      );
      switch (operator) {
      case ":":
        return isMatch;
      case "!:":
        return !isMatch;
      default:
        return false;
      }
    }

    const itemValue = document[propertyKey as keyof Document];
    if (typeof itemValue === "string") {
      switch (operator) {
      case "=":
        return itemValue === value;
      case ":":
        return isCaseInsensitivePartialStringMatch(itemValue, value);
      default:
        return false;
      }
    }
    if (Array.isArray(itemValue)) {
      switch (operator) {
      case "=":
        return itemValue.includes(value);
      case ":":
        return itemValue.some((item) =>
          isCaseInsensitivePartialStringMatch(item, value),
        );
      default:
        return false;
      }
    }
    if (typeof itemValue === "number") {
      switch (operator) {
      case ">=":
        return itemValue >= parseInt(value);
      case "<=":
        return itemValue <= parseInt(value);
      default:
        return false;
      }
    }
    return false;
  };

  return operation === "and"
    ? tokens.every(itemFilterFunction)
    : tokens.some(itemFilterFunction);
};
