import React, { FC } from "react";
import Header, { HeaderProps } from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import KeyValuePairs, {
  KeyValuePairsProps,
} from "@cloudscape-design/components/key-value-pairs";

export interface KeyValuePairCardProps
  extends Pick<KeyValuePairsProps, "items" | "columns"> {
  headerTitle?: HeaderProps["children"];
  headerVariant?: HeaderProps["variant"];
}

const KeyValuePairCard: FC<KeyValuePairCardProps> = ({
  items,
  columns,
  headerTitle,
  headerVariant,
  ...props
}: KeyValuePairCardProps) => {
  return (
    <Container
      data-testid="key-value-pair-card"
      header={<Header variant={headerVariant}>{headerTitle} </Header>}
      {...props}
    >
      <KeyValuePairs columns={columns} items={items} />
    </Container>
  );
};

export default KeyValuePairCard;
