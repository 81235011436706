import { XCMInsightsUDSCoreClient } from "@amzn/xcm-insights-uds-core-service-client";
import { Endpoint } from "@smithy/types";
import { fetchAuthSession } from "aws-amplify/auth";
import { getSettings } from "./settings";

let client: XCMInsightsUDSCoreClient;
let endpoint: Endpoint;

export function getClient() {
  if (!client) {
    client = new XCMInsightsUDSCoreClient({
      endpoint: async () => {
        if (!endpoint) {
          const url = new URL((await getSettings()).API_ENDPOINT);
          endpoint = {
            protocol: url.protocol,
            hostname: url.hostname,
            port: parseInt(url.port),
            path: url.pathname,
          };
        }
        return endpoint;
      },
      apiKey: async () =>
        "Bearer " +
        ((await fetchAuthSession()).tokens?.idToken?.toString() ?? ""),
    });
  }
  return client;
}
