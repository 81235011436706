import { GetProjectsCommandInput } from "../../client/interfaces";

export const APP_NAME = "Unified Document Store (UDS)";
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SPLIT_PANEL_WIDTH_PIXELS = 500;
export const EMPTY_PLACEHOLDER = "-";
export const ARRAY_SEPARATOR = ", ";
export const UNKNOWN_ALIAS = "unknown";
export const UNKNOWN_FILE_EXTENSION = "unknown";
export const NUM_PAST_YEAR_TO_GENERATE_YEAR_QUARTER_MONTH = 10;
export const EARLIEST_YEAR_FOR_YEAR_QUARTER_MONTH = 2017;
export const FILE_UPLOAD_TIMEOUT = 300000; // 5 minutes
export const MINIMUM_DOCUMENT_TITLE_LENGTH = 3;
export const DEFAULT_DEBOUNCE_DELAY_MILLIS = 500;
/**
 * Match valid operator. Group 1 is the operator and Group 2 is the value.
 */
export const VALID_OPERATORS_REGEX =
  /^(<|<=|>|>=|:|!:|=|!=|^){0,1}([^<=>:!^]*)$/;
/**
 * Used as a placeholder key for free text search.
 */
export const FREE_FILTER_TERM_KEY: keyof Pick<
  GetProjectsCommandInput,
  "freeFilterTerm"
> = "freeFilterTerm";

export enum RouteParams {
  PROJECT_TITLE = "projectTitle",
  PROJECT_ID = "projectId",
  DOCUMENT_ID = "documentId",
}

export enum RoutePath {
  HOME = "home",
  ALL_PROJECTS = "projects",
  PROJECT = "projects/:projectTitle",
  DOCUMENT_DOWNLOAD = "projects/:projectId/documents/:documentId/download",
}

/**
 * Download link to match {@link RoutePath.DOCUMENT_DOWNLOAD}.
 */
export const DOWNLOAD_LINK = (projectId: string, documentId: string) =>
  `/projects/${projectId}/documents/${documentId}/download`;

/**
 * Project link to match {@link RoutePath.PROJECT}.
 */
export const PROJECT_LINK = (projectTitle: string) =>
  `/projects/${encodeURIComponent(projectTitle)}`;

/**
 * Document detail link to match {@link RoutePath.PROJECT} + search parameters.
 */
export const DOCUMENT_DETAIL_LINK = (
  projectTitle: string,
  documentTitle: string,
) =>
  `/projects/${encodeURIComponent(projectTitle)}?documentTitle=${encodeURIComponent(documentTitle)}`;

export const BASE_URL = `${location.protocol}//${location.host}`;
