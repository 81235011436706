import React, { FC, ReactElement } from "react";
import SiteHeader from "./components/header/SiteHeader";
import ViewPort from "./views/ViewPort";
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";

const App: FC = (): ReactElement => {
  return (
    <div data-testid="cloudscape">
      <SiteHeader />
      <ErrorBoundary>
        <ViewPort />
      </ErrorBoundary>
    </div>
  );
};

export default App;
