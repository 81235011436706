import React, { createContext, ReactNode, useEffect } from "react";
import { signInWithRedirect, AuthError } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import ScreenLoader from "../components/common/ScreenLoader";
import { useSettingsWithAuthConfigured, useUserIdentity } from "../hooks";
import { UserIdentity, UserRole } from "../client/interfaces";
import { LINKS_CONFIG } from "../data/constants/links";
import styles from "./AuthContext.module.scss";

interface AuthContextType extends Partial<UserIdentity> {
  isEditor?: boolean;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

function isAuthError(error: any): boolean {
  return error instanceof AuthError;
}

function getMostPrivilegedRole(
  userRoles: UserRole[] = [],
): UserRole | undefined {
  if (userRoles.includes(UserRole.ADMIN)) {
    return UserRole.ADMIN;
  } else if (userRoles.includes(UserRole.AUTHOR)) {
    return UserRole.AUTHOR;
  } else if (userRoles.includes(UserRole.READER)) {
    return UserRole.READER;
  }
  return undefined;
}

export const AuthContextProvider = (props: { children?: ReactNode }) => {
  const {
    isLoading: isLoadingSettings,
    data: settings,
    error: settingsError,
  } = useSettingsWithAuthConfigured();
  const { isLoading, data: userIdentity, error } = useUserIdentity(settings);

  useEffect(() => {
    const hubListenerCancel = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
      case "customOAuthState":
        // Redirect user to the original destination
        window.location.replace(payload.data);
        break;
      default:
        break;
      }
    });
    return () => hubListenerCancel();
  }, []);

  useEffect(() => {
    if (isAuthError(error) && settings) {
      signInWithRedirect({
        customState: window.location.href, // Save the user's original destination to state
        provider: {
          custom: settings.COGNITO_SIGN_IN_CUSTOM_PROVIDER,
        },
      });
    }
  }, [error, settings]);

  const contextValue: AuthContextType = {
    ...userIdentity,
    isEditor:
      userIdentity?.role?.includes(UserRole.ADMIN) ||
      userIdentity?.role?.includes(UserRole.AUTHOR),
  };

  const userRole = getMostPrivilegedRole(userIdentity?.role);

  const errorElement = isAuthError(error) ? (
    <div className={styles.loaderPadding}>
      <ScreenLoader message="Redirecting you to login..." />
    </div>
  ) : (
    <div className={styles.authError}>
      <p data-testid="auth-error-message">
        An error has occurred when authenticating your identity. If error
        persists, please contact the administrator at&nbsp;
        <a
          href={LINKS_CONFIG.CONTACT_US}
          target="_blank"
          rel="noopener noreferrer"
        >
          {LINKS_CONFIG.CONTACT_US}
        </a>
      </p>
    </div>
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoading || isLoadingSettings ? (
        <div className={styles.loaderPadding}>
          <ScreenLoader />
        </div>
      ) : !error && !settingsError ? (
        <div
          className={styles.roleBasedDisplay}
          data-user-role={userRole}
          data-testid="authenticated-content"
        >
          {props.children}
        </div>
      ) : (
        errorElement
      )}
    </AuthContext.Provider>
  );
};
