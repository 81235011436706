
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_1t4x7_157",
  "arrow": "awsui_arrow_18eso_1t4x7_157",
  "has-caret": "awsui_has-caret_18eso_1t4x7_220",
  "placeholder": "awsui_placeholder_18eso_1t4x7_223",
  "pressed": "awsui_pressed_18eso_1t4x7_236",
  "disabled": "awsui_disabled_18eso_1t4x7_239",
  "in-filtering-token": "awsui_in-filtering-token_18eso_1t4x7_249",
  "readonly": "awsui_readonly_18eso_1t4x7_255",
  "invalid": "awsui_invalid_18eso_1t4x7_277",
  "warning": "awsui_warning_18eso_1t4x7_286",
  "inline-tokens": "awsui_inline-tokens_18eso_1t4x7_322"
};
  