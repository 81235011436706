import omit from "lodash.omit";
import { Project } from "../../../client/interfaces";
import { arrayToDict, objectMap } from "../../../helpers";
import { PROJECT_FORM_INPUTS } from "./projectFormConfig";
import {
  ProjectFormBoolean,
  ProjectFormSubmitAction,
  ProjectInputValue,
} from "./projectFormTypes";

interface GetInitialProjectFormValuesProps {
  projectFormSubmitAction: ProjectFormSubmitAction;
  project?: Project;
}

interface GetInitialDocumentFormValuesOutput {
  defaultProjectInputValue: ProjectInputValue;
  defaultProjectFormIsError: ProjectFormBoolean;
  defaultProjectFormIsModified: ProjectFormBoolean;
}

function withNonProjectInputFieldsRemoved(
  project?: Project,
): Partial<Project> | undefined {
  return project
    ? omit(
      project,
      "projectCategory",
      "lastUpdatedBy",
      "lastUpdateDate",
      "lastUploadDate",
      "projectId",
    )
    : undefined;
}

const PROJECT_INPUT_DEFAULT_VALUE = ({
  project,
}: {
  project?: Partial<Project>;
} = {}): ProjectInputValue => ({
  projectTitle: project?.projectTitle ?? "",
  projectDescription: project?.projectDescription ?? "",
  ...project,
});

export function getInitialProjectFormValues({
  projectFormSubmitAction,
  project,
}: GetInitialProjectFormValuesProps): GetInitialDocumentFormValuesOutput {
  let defaultProjectInputValue: ProjectInputValue;
  switch (projectFormSubmitAction) {
  case ProjectFormSubmitAction.EDIT:
    defaultProjectInputValue = PROJECT_INPUT_DEFAULT_VALUE({
      project: withNonProjectInputFieldsRemoved(project),
    });
    break;
  case ProjectFormSubmitAction.CREATE:
  default:
    defaultProjectInputValue = PROJECT_INPUT_DEFAULT_VALUE();
    break;
  }

  // Run each default input value through the validation function
  const defaultProjectFormIsError: ProjectFormBoolean = objectMap(
    arrayToDict(
      Object.values(
        PROJECT_FORM_INPUTS({
          defaultValue: defaultProjectInputValue,
        }),
      ).map((item) => item.props),
      "fieldId",
    ),
    (props) =>
      !(
        props.validate?.(defaultProjectInputValue[props.fieldId]).isValid ??
        true
      ),
  );

  const defaultProjectFormIsModified: ProjectFormBoolean = {};

  return {
    defaultProjectInputValue,
    defaultProjectFormIsError,
    defaultProjectFormIsModified,
  };
}
