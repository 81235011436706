import { DateTime, Duration } from "luxon";
import { DateComparisonOperator } from "../client/interfaces";

export function toLocalDate(
  unixTimestamp: undefined,
  timeZone?: string,
): undefined;
export function toLocalDate(unixTimestamp: number, timeZone?: string): string;
export function toLocalDate(
  unixTimestamp?: number,
  timeZone?: string,
): string | undefined;
export function toLocalDate(
  unixTimestamp?: number,
  timeZone = "local",
): string | undefined {
  if (!unixTimestamp) {
    return undefined;
  }
  const date = DateTime.fromMillis(unixTimestamp, { zone: timeZone });
  return date.toFormat("yyyy-MM-dd");
}

export function toISODatetime(date: Date, timeZone = "local"): string {
  return DateTime.fromJSDate(date, { zone: timeZone }).toISO() ?? "";
}

/**
 * Convert ISO date string to unix timestamp (in milliseconds)
 * @param isoDateString ISO date string in YYYY-MM-DDThh:mm:ss.sssZ format.
 * If time is not specified, it is assumed to be midnight (beginning of the day).
 * If timezone is not specified, it is assumed to be local time zone.
 * @returns unix timestamp in milliseconds
 */
export function toUnixTimestamp(isoDateString: string): number | undefined {
  const datetime = DateTime.fromISO(isoDateString);
  return datetime.isValid ? datetime.toMillis() : undefined;
}

export function getUnixTimestampDurationBeforeNow(duration?: Duration): number {
  return DateTime.now()
    .minus(duration ?? {})
    .toMillis();
}

export function getCurrentYear(timeZone = "local"): number {
  return DateTime.local({ zone: timeZone }).year;
}

/**
 * Generate string array of year/quarter/month
 * @param startYear start year of the generated year/quarter/month string array
 * @param endYear end year of the generated year/quarter/month string array. Default to current year
 * @param timeZone time zone. Default to user's local time zone
 * @returns string array of year/quarter/month
 */
export function generateYearQuarterMonth(
  startYear: number,
  endYear?: number,
  timeZone = "local",
): string[] {
  endYear = endYear ?? getCurrentYear(timeZone);
  const yearQuartersMonths: string[] = [];
  for (let year = endYear; year >= startYear; year--) {
    yearQuartersMonths.push(`${year}`);
    for (let quarter = 1; quarter <= 4; quarter++) {
      yearQuartersMonths.push(`${year}-Q${quarter}`);
    }
    for (let month = 1; month <= 12; month++) {
      yearQuartersMonths.push(`${year}-${month.toString().padStart(2, "0")}`);
    }
  }
  return yearQuartersMonths;
}

export function toDateComparisonOperator(
  operator: string,
): DateComparisonOperator | undefined {
  switch (operator) {
  case ">=":
    return DateComparisonOperator.GTE;
  case "<=":
    return DateComparisonOperator.LTE;
  default:
    return undefined;
  }
}
