import { CreateProjectInput, Project } from "../../../client/interfaces";

export enum ProjectFormSubmitAction {
  CREATE = "Create",
  EDIT = "Update",
}

export interface ProjectFormSubmitDetail {
  project: Project;
  noChanges?: boolean;
}

export type ProjectFormFieldId = keyof CreateProjectInput;

export type ProjectInputValue = CreateProjectInput;

export type ProjectFormBoolean = { [k in ProjectFormFieldId]?: boolean };
