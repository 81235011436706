import React, { FC, useContext, useEffect } from "react";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import ButtonDropdown, {
  ButtonDropdownProps,
} from "@cloudscape-design/components/button-dropdown";
import { Document, Project, UserRole } from "../../client/interfaces";
import KeyValuePairCard from "../../components/card/KeyValuePairCard";
import FlashBarMessages, {
  FlashbarMessagesProps,
} from "../../components/common/FlashbarMessages";
import { useDocuments, useFlashbarMessages } from "../../hooks";
import { GET_DOCUMENTS_ERROR } from "../../data/constants/errorMessage";
import { AuthContext } from "../../context/AuthContext";
import styles from "./ProjectDetail.module.scss";
import {
  PROJECT_DETAIL_PROJECT_INFORMATION,
  getProjectDetailContextMenuItems,
} from "./projectDetail";
import ProjectDetailDocumentsTable from "./projectDetail/ProjectDetailDocumentsTable";

interface ProjectDetailEditProjectProps {
  project: Project;
}

export interface ProjectDetailActionItemClickProps {
  project: Project;
  documents?: Document[];
  event: CustomEvent<ButtonDropdownProps.ItemClickDetails>;
}

export interface ProjectDetailProps {
  project: Project;
  flashbarMessage?: NonNullable<FlashbarMessagesProps["messages"]>[number];
  onEditClick?: (props: ProjectDetailEditProjectProps) => void;
  onActionItemClick?: (props: ProjectDetailActionItemClickProps) => void;
}

const ProjectDetail: FC<ProjectDetailProps> = ({
  project,
  flashbarMessage,
  onEditClick,
  onActionItemClick,
}) => {
  const { isEditor } = useContext(AuthContext);
  const { messages, addErrorMessage, removeAllMessages } = useFlashbarMessages(
    flashbarMessage ? [flashbarMessage] : [],
  );

  const {
    data: documents,
    isLoading,
    error: getDocumentsError,
  } = useDocuments(project?.projectId);

  useEffect(() => {
    if (!flashbarMessage) {
      removeAllMessages();
    }
  }, [flashbarMessage]);

  useEffect(() => {
    if (getDocumentsError) {
      addErrorMessage?.(getDocumentsError, GET_DOCUMENTS_ERROR);
    }
  }, [getDocumentsError]);

  return (
    <div className={styles.projectDetail} data-testid="project-detail">
      <ContentLayout
        notifications={
          <FlashBarMessages
            data-testid="project-detail-flashbar-messages"
            data-project-detail-selector="flashbar-messages"
            messages={messages}
          />
        }
        header={
          <Header
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
                data-project-detail-selector="user-actions"
              >
                <ButtonDropdown
                  data-testid="project-detail-action-button"
                  items={getProjectDetailContextMenuItems({ isEditor })}
                  onItemClick={(event) =>
                    project &&
                    onActionItemClick?.({
                      project,
                      documents,
                      event,
                    })
                  }
                >
                  Actions
                </ButtonDropdown>
                <Button
                  data-testid="project-detail-edit-button"
                  data-roles={`${UserRole.ADMIN} ${UserRole.AUTHOR}`}
                  onClick={() => onEditClick?.({ project })}
                >
                  Edit
                </Button>
              </SpaceBetween>
            }
          ></Header>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <KeyValuePairCard
            data-testid="project-detail-project-information"
            columns={1}
            items={PROJECT_DETAIL_PROJECT_INFORMATION(project)}
            headerTitle="Project information"
            headerVariant="h3"
          />
          <ProjectDetailDocumentsTable
            project={project}
            documents={documents}
            isLoading={isLoading}
          />
        </SpaceBetween>
      </ContentLayout>
    </div>
  );
};

export default ProjectDetail;
