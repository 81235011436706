import { ReactNode } from "react";
import { ValidationFunction, ValidationOutput } from "../client/interfaces";
import { toUnixTimestamp } from "./date";

type SupportedValidatorTypes = string | string[] | File[];

function isString(value: SupportedValidatorTypes): value is string {
  return typeof value === "string";
}

function isStringArray(value: SupportedValidatorTypes): value is string[] {
  return typeof value[0] === "string";
}

/**
 * A builder class for creating a validation function
 * chaining multiple validation functions.
 * @typeParam T - The type of the value to be validated.
 */
export class Validator<T> {
  private validators: ValidationFunction<T>[] = [];

  public static builder<T>(): Validator<T> {
    return new Validator<T>();
  }

  public addValidator(validator: ValidationFunction<T>): Validator<T> {
    this.validators.push(validator);
    return this;
  }

  public build(): ValidationFunction<T> {
    return (value: T): ValidationOutput => {
      for (const validator of this.validators) {
        const result = validator(value);
        if (!result.isValid) {
          return result;
        }
      }
      return { isValid: true };
    };
  }
}

export function requiredValidator<T extends SupportedValidatorTypes>(
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(value: T): ValidationOutput {
    const isValid = value.length > 0;
    const errorText = customErrorText ? customErrorText : "This is required";
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}

export function minLengthValidator<T extends SupportedValidatorTypes>(
  minLength = 1,
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(value: T): ValidationOutput {
    const isValid = value.length >= minLength;
    const defaultErrorText = isString(value)
      ? `This must be at least ${minLength} characters long`
      : `This must include at least ${minLength} items`;
    const errorText = customErrorText ? customErrorText : defaultErrorText;
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}

export function regexValidator<T extends SupportedValidatorTypes>(
  regex: RegExp,
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(value: T): ValidationOutput {
    const isValid = isString(value)
      ? regex.test(value)
      : isStringArray(value)
        ? value.every((v) => regex.test(v))
        : value.every((file) => regex.test(file.name));
    const defaultErrorText = `This does not satisfy regular expression ${regex}`;
    const errorText = customErrorText ? customErrorText : defaultErrorText;
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}

/**
 * @see {@link toUnixTimestamp} on how date strings are handled when converting to unix timestamp
 */
export function dateComparisonValidator<T extends SupportedValidatorTypes>(
  compareFn: (valueInUnixTimestampMillis: number) => boolean,
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(value: T): ValidationOutput {
    const isValid = isString(value)
      ? compareFn(toUnixTimestamp(value) ?? 0)
      : isStringArray(value)
        ? value.every((v) => compareFn(toUnixTimestamp(v) ?? 0))
        : value.every((v) => compareFn(v.lastModified));
    const defaultErrorText = "This does not satisfy the datetime condition";
    const errorText = customErrorText ? customErrorText : defaultErrorText;
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}

/**
 * Validate with an external validator. This is helpful for validating values
 * through third-party API calls.
 */
export function externalValidator<T extends SupportedValidatorTypes>(
  externalIsValidated: () => boolean = () => true,
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(): ValidationOutput {
    const isValid = externalIsValidated();
    const errorText = customErrorText ? customErrorText : "This is invalid";
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}

/**
 * Validate with multiple validation function with logical OR.
 * This is helpful for validating values when only one condition is
 * needed for a valid value.
 */
export function logicalOrValidator<T extends SupportedValidatorTypes>(
  validationFunctions: ValidationFunction<T>[],
  customErrorText?: ReactNode,
): ValidationFunction<T> {
  return function validate(value: T): ValidationOutput {
    const isValid =
      validationFunctions.length === 0 ||
      validationFunctions.some((validate) => validate(value).isValid);
    const errorText = customErrorText ? customErrorText : "This is invalid";
    return { isValid, errorText: isValid ? undefined : errorText };
  };
}
