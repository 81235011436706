import React, { FC, useContext } from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { AuthContext } from "../../context/AuthContext";
import styles from "./SiteHeader.module.css";

const SiteHeader: FC = () => {
  const { givenName, familyName, email } = useContext(AuthContext);
  const userName = `${givenName} ${familyName}`.trim();

  return (
    <div className={styles.nav} id="h">
      <TopNavigation
        identity={{
          href: "/",
          title: "XCM Insights",
        }}
        utilities={[
          {
            type: "menu-dropdown",
            text: userName,
            iconName: "user-profile",
            ariaLabel: "User name",
            description: `Logged in as ${email}`,
            items: [],
          },
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu",
        }}
        data-testid="site-header"
      />
    </div>
  );
};

export default SiteHeader;
