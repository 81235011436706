import { DocumentMetadataOptionsForInputs } from "../../../client/interfaces";
import {
  externalValidator,
  requiredValidator,
  Validator,
} from "../../../helpers";
import {
  TextAreaInputProps,
  TypedTextAreaInput,
} from "../../../components/inputs/TextAreaInput";
import { ProjectMetadataName } from "../../common/projectConstant";
import {
  AutosuggestInputProps,
  TypedAutosuggestInput,
} from "../../../components/inputs/AutosuggestInput";
import { FormInputsComponentWithProps } from "../../common/form/inputComponentTypes";
import { ProjectFormFieldId, ProjectInputValue } from "./projectFormTypes";

const ProjectTextAreaInput = TypedTextAreaInput<ProjectFormFieldId>();
const ProjectAutosuggestInput = TypedAutosuggestInput<ProjectFormFieldId>();

export interface ProjectFormInputsProps {
  options?: DocumentMetadataOptionsForInputs;
  defaultValue: ProjectInputValue;
  externalValidate?: {
    [key in Extract<ProjectFormFieldId, "projectTitle">]?: () => boolean;
  };
}

type ProjectFormInputsKey = keyof ReturnType<typeof PROJECT_FORM_INPUTS>;

/**
 * This object defines the type of each input field, the component
 * used, labels, description, validation rules, etc.
 */
export const PROJECT_FORM_INPUTS = ({
  options,
  defaultValue,
  externalValidate,
}: ProjectFormInputsProps): {
  [k in ProjectMetadataName.PROJECT_DESCRIPTION]: FormInputsComponentWithProps<
    TextAreaInputProps<ProjectFormFieldId>
  >;
} & {
  [k in ProjectMetadataName.PROJECT_TITLE]: FormInputsComponentWithProps<
    AutosuggestInputProps<ProjectFormFieldId>
  >;
} => ({
  [ProjectMetadataName.PROJECT_DESCRIPTION]: {
    component: ProjectTextAreaInput,
    props: {
      label: ProjectMetadataName.PROJECT_DESCRIPTION,
      placeholder: "A brief summary of the project contents",
      defaultValue: defaultValue.projectDescription,
      fieldId: "projectDescription",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
    },
  },
  [ProjectMetadataName.PROJECT_TITLE]: {
    component: ProjectAutosuggestInput,
    props: {
      placeholder: "Project title",
      defaultValue: defaultValue.projectTitle,
      fieldId: "projectTitle",
      required: true,
      options: options?.projects?.map((project) => ({
        value: project.label ?? project.value,
      })),
      filteringType: "auto",
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .addValidator(
          externalValidator(
            externalValidate?.projectTitle,
            "Project title already exists",
          ),
        )
        .build(),
    },
  },
});

export const PROJECT_FORM_PROJECT_INFORMATION = (
  props: ProjectFormInputsProps,
) => {
  const keys: ProjectFormInputsKey[] = [
    ProjectMetadataName.PROJECT_TITLE,
    ProjectMetadataName.PROJECT_DESCRIPTION,
  ];
  return keys.map((metadataName) => PROJECT_FORM_INPUTS(props)[metadataName]);
};
