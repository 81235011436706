import { ResourcesConfig } from "aws-amplify";
import { WebsiteSettings } from "../../client/interfaces";

export function getAuthConfig(settings: WebsiteSettings): {
  Auth: ResourcesConfig["Auth"];
} {
  return {
    Auth: {
      Cognito: {
        userPoolId: settings.COGNITO_USER_POOL_ID,
        userPoolClientId: settings.COGNITO_WEB_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: settings.COGNITO_DOMAIN,
            scopes: ["openid"],
            redirectSignIn: [window.location.origin],
            redirectSignOut: [],
            responseType: "code",
          },
        },
      },
    },
  };
}
