
    import './styles.scoped.css';
    export default {
  "preference-icon--svg": "awsui_preference-icon--svg_d4xxm_e8uh4_141",
  "preference-icon--layout-border": "awsui_preference-icon--layout-border_d4xxm_e8uh4_147",
  "preference-icon--layout-background": "awsui_preference-icon--layout-background_d4xxm_e8uh4_151",
  "preference-icon--layout-header": "awsui_preference-icon--layout-header_d4xxm_e8uh4_154",
  "preference-icon--border": "awsui_preference-icon--border_d4xxm_e8uh4_157",
  "preference-icon--primary-button": "awsui_preference-icon--primary-button_d4xxm_e8uh4_161",
  "preference-icon--secondary": "awsui_preference-icon--secondary_d4xxm_e8uh4_164",
  "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_d4xxm_e8uh4_167",
  "preference-icon--separator": "awsui_preference-icon--separator_d4xxm_e8uh4_170",
  "preference-icon--focus-text": "awsui_preference-icon--focus-text_d4xxm_e8uh4_173",
  "preference-icon-refresh--svg": "awsui_preference-icon-refresh--svg_d4xxm_e8uh4_177",
  "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_d4xxm_e8uh4_183",
  "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_d4xxm_e8uh4_186",
  "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_d4xxm_e8uh4_189",
  "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_d4xxm_e8uh4_192",
  "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_d4xxm_e8uh4_195",
  "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_d4xxm_e8uh4_198",
  "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_d4xxm_e8uh4_202",
  "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_d4xxm_e8uh4_205",
  "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_d4xxm_e8uh4_208",
  "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_d4xxm_e8uh4_211",
  "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_d4xxm_e8uh4_214"
};
  