import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import { CollectionPreferencesProps } from "@cloudscape-design/components/collection-preferences";
import { TableProps } from "@cloudscape-design/components/table";
import {
  DocumentMetadataOptionsForInputs,
  DocumentStatus,
} from "../../../client/interfaces";
import { DEFAULT_PAGE_SIZE } from "../../../data/constants/common";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../../common/documentConstant";
import {
  PROJECT_PROPERTY_KEY,
  ProjectMetadataName,
} from "../../common/projectConstant";
import { DOCUMENT_TABLE_FILTERING_PROPERTIES } from "../../common/documentTableFilterProperty";

const PROJECT_TABLE_COLUMN_DISPLAY: TableProps["columnDisplay"] = [
  {
    id: ProjectMetadataName.PROJECT_TITLE,
    visible: true,
  },
  {
    id: ProjectMetadataName.PROJECT_DESCRIPTION,
    visible: true,
  },
  {
    id: ProjectMetadataName.LAST_UPLOAD_DATE,
    visible: true,
  },
];

export const PROJECT_TABLE_PREFERENCES: CollectionPreferencesProps["preferences"] =
  {
    contentDensity: "comfortable",
    contentDisplay: PROJECT_TABLE_COLUMN_DISPLAY,
    pageSize: DEFAULT_PAGE_SIZE,
    stripedRows: false,
    wrapLines: false,
  };

export const PROJECT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS: ReadonlyArray<CollectionPreferencesProps.ContentDisplayOption> =
  PROJECT_TABLE_COLUMN_DISPLAY.map((item) => {
    if (item.id === DocumentMetadataName.DOCUMENT_TITLE) {
      return { id: item.id, label: item.id, alwaysVisible: true };
    }
    return { id: item.id, label: item.id };
  });

export const PROJECT_TABLE_FILTERING_PROPERTIES: PropertyFilterProps["filteringProperties"] =
  [
    ...[ProjectMetadataName.PROJECT_TITLE].map((property) => ({
      key: PROJECT_PROPERTY_KEY[property],
      operators: property === ProjectMetadataName.PROJECT_TITLE ? [":"] : ["="],
      defaultOperator:
        property === ProjectMetadataName.PROJECT_TITLE ? ":" : "=",
      propertyLabel: property,
      groupValuesLabel: `${property} values`,
    })),
    ...DOCUMENT_TABLE_FILTERING_PROPERTIES,
  ];

interface ProjectTableFilteringOptions {
  options?: DocumentMetadataOptionsForInputs;
  hideExpiredStatusOption?: boolean;
}

export const PROJECT_TABLE_FILTERING_OPTIONS = ({
  options,
  hideExpiredStatusOption,
}: ProjectTableFilteringOptions = {}): PropertyFilterProps["filteringOptions"] => [
  ...(options?.projects ?? []).map((value) => ({
    propertyKey: PROJECT_PROPERTY_KEY[ProjectMetadataName.PROJECT_TITLE],
    value: value.value,
  })),
  ...(options?.documentTitles ?? []).map((value) => ({
    propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TITLE],
    value: value.value,
  })),
  ...(options?.bigRocks ?? []).map((value) => ({
    propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.BIG_ROCKS],
    value: value.value,
  })),
  ...(options?.documentContentTypes ?? []).map((value) => ({
    propertyKey:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_CONTENT_TYPES],
    value: value.value,
  })),
  ...(options?.documentYearQuarterMonth ?? []).map((value) => ({
    propertyKey:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH],
    value: value.value,
  })),
  ...(options?.marketplaces ?? []).map((value) => ({
    propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.MARKETPLACES],
    value: value.value,
  })),
  ...(options?.primarySubjectAreas ?? []).map((value) => ({
    propertyKey:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.PRIMARY_SUBJECT_AREA],
    value: value.value,
  })),
  ...(options?.additionalSubjectAreas ?? []).map((value) => ({
    propertyKey:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS],
    value: value.value,
  })),
  ...(options?.authors ?? []).map((value) => ({
    propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.AUTHOR],
    value: value.value,
  })),
  ...Object.values(DocumentStatus)
    .filter((status) =>
      hideExpiredStatusOption ? status !== DocumentStatus.EXPIRED : true,
    )
    .map((value) => ({
      propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.STATUS],
      value,
    })),
  ...(options?.classifications ?? []).map((value) => ({
    propertyKey:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_CLASSIFICATION],
    value: value.value,
  })),
  ...(options?.supportedDocumentTypes ?? []).map((value) => ({
    propertyKey: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TYPE],
    value: value.value,
  })),
];
