
    import './styles.scoped.css';
    export default {
  "navigation": "awsui_navigation_2p2ab_tos0m_141",
  "is-navigation-open": "awsui_is-navigation-open_2p2ab_tos0m_169",
  "animating": "awsui_animating_2p2ab_tos0m_173",
  "openNavigation": "awsui_openNavigation_2p2ab_tos0m_1",
  "animated-content": "awsui_animated-content_2p2ab_tos0m_186",
  "hide-navigation": "awsui_hide-navigation_2p2ab_tos0m_195"
};
  