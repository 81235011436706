import React, { ReactNode, useState } from "react";
import { Document, Project } from "../../client/interfaces";
import {
  CREATE_NEW_DOCUMENT,
  DOCUMENT_EDIT_SUCCESS_MESSAGE,
  DOCUMENT_NO_CHANGES_INFO_MESSAGE,
  DOCUMENT_UPLOAD_SUCCESS_MESSAGE,
  EDIT_DOCUMENT,
} from "../common/documentConstant";
import { CopyLink } from "../common/shareLinkCopy";
import { ARRAY_SEPARATOR } from "../../data/constants/common";
import {
  CREATE_NEW_PROJECT,
  EDIT_PROJECT,
  PROJECT_CREATE_SUCCESS_MESSAGE,
  PROJECT_EDIT_SUCCESS_MESSAGE,
  PROJECT_NO_CHANGES_INFO_MESSAGE,
} from "../common/projectConstant";
import DocumentDetail, {
  DocumentDetailActionItemClickProps,
  DocumentDetailProps,
} from "./DocumentDetail";
import DocumentForm from "./DocumentForm";
import { DocumentFormSubmitAction } from "./documentForm";
import { scrollSplitPanelContent } from "./splitPanelScroll";
import { DocumentDetailAction } from "./documentDetail";
import ProjectDetail, {
  ProjectDetailActionItemClickProps,
  ProjectDetailProps,
} from "./ProjectDetail";
import { ProjectFormSubmitAction } from "./projectForm";
import ProjectForm from "./ProjectForm";
import { ProjectDetailAction } from "./projectDetail";

interface UseDocumentDashboardSplitPanelElementProps {
  setSplitPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardDivRef?: React.RefObject<HTMLDivElement>;
  splitPanelSelector?: string;
  setSearchParamOpenedDocumentTitle?: (title: string) => void;
  removeSearchParamOpenedDocumentTitle?: () => void;
  onDocumentChange?: (document: Document) => void;
  onProjectChange?: (project: Project) => void;
  handleDeleteDocument?: (props: Required<BaseDocumentProps>) => void;
  handleDownloadDocument?: (props: Required<BaseDocumentProps>) => void;
  handleExportDocumentMetadata?: (props: Required<BaseDocumentProps>) => void;
  handleDeleteProject?: (props: BaseProjectProps) => void;
  handleExportDocumentMetadataByProject?: (
    props: ExportDocumentMetadataByProjectProps,
  ) => void;
}

interface BaseDocumentProps {
  document: Document;
  project?: Project;
}

interface ViewDocumentDetailProps extends BaseDocumentProps {
  flashbarMessage?: DocumentDetailProps["flashbarMessage"];
}

type EditDocumentProps = BaseDocumentProps;

interface CreateDocumentProps {
  project?: Project;
}

type DuplicateDocumentProps = BaseDocumentProps;

interface BaseProjectProps {
  project: Project;
}

interface ViewProjectDetailProps {
  projects: [Project, ...Project[]];
  flashbarMessage?: ProjectDetailProps["flashbarMessage"];
}

type EditProjectProps = BaseProjectProps;

interface ExportDocumentMetadataByProjectProps extends BaseProjectProps {
  documents?: Document[];
}

export function useDashboardSplitPanelElement({
  setSplitPanelOpen,
  dashboardDivRef,
  splitPanelSelector = "",
  setSearchParamOpenedDocumentTitle,
  removeSearchParamOpenedDocumentTitle,
  onDocumentChange,
  onProjectChange,
  handleDeleteDocument,
  handleDownloadDocument,
  handleExportDocumentMetadata,
  handleDeleteProject,
  handleExportDocumentMetadataByProject,
}: UseDocumentDashboardSplitPanelElementProps) {
  const [splitPanelHeader, setSplitPanelHeader] = useState<string>("");
  const [splitPanelElement, setSplitPanelElement] = useState<ReactNode>();
  const [openedDocument, setOpenedDocument] = useState<Document>();
  const [openedProjects, setOpenedProjects] = useState<Project[]>();

  function handleDocumentDetailActionItemClick({
    project,
    document,
    event,
  }: DocumentDetailActionItemClickProps) {
    switch (event.detail.id) {
    case DocumentDetailAction.DELETE:
      handleDeleteDocument?.({ project, document });
      break;
    case DocumentDetailAction.DOWNLOAD:
      handleDownloadDocument?.({ project, document });
      break;
    case DocumentDetailAction.DUPLICATE:
      duplicateDocument({ project, document });
      break;
    case DocumentDetailAction.EXPORT:
      handleExportDocumentMetadata?.({ project, document });
      break;
    case DocumentDetailAction.SHARE:
      CopyLink.copyDocumentDetailLink(project, document);
      break;
    }
  }

  function viewDocumentDetail({
    document,
    project,
    flashbarMessage,
  }: ViewDocumentDetailProps) {
    setSplitPanelOpen(true);
    setSplitPanelHeader(document.documentTitle);
    setOpenedDocument(document);
    setOpenedProjects(undefined);
    setSearchParamOpenedDocumentTitle?.(document.documentTitle);
    scrollSplitPanelContentToTop();
    setSplitPanelElement(
      <DocumentDetail
        project={project}
        document={document}
        flashbarMessage={flashbarMessage}
        onEditClick={editDocument}
        onActionItemClick={handleDocumentDetailActionItemClick}
      />,
    );
  }

  function editDocument({ document, project }: EditDocumentProps) {
    setSplitPanelOpen(true);
    setSplitPanelHeader(EDIT_DOCUMENT);
    setOpenedDocument(document);
    setOpenedProjects(undefined);
    setSearchParamOpenedDocumentTitle?.(document.documentTitle);
    setSplitPanelElement(
      <div key={`${DocumentFormSubmitAction.EDIT}${document.documentId}`}>
        <DocumentForm
          project={project}
          document={document}
          submitAction={DocumentFormSubmitAction.EDIT}
          onCancel={() => viewDocumentDetail({ document, project })}
          onSubmit={({ document: editedDocument, noChanges }) => {
            viewDocumentDetail({
              document: editedDocument,
              flashbarMessage: noChanges
                ? DOCUMENT_NO_CHANGES_INFO_MESSAGE
                : DOCUMENT_EDIT_SUCCESS_MESSAGE,
            });
            !noChanges && onDocumentChange?.(document);
          }}
        />
      </div>,
    );
  }

  function createDocument({ project }: CreateDocumentProps = {}) {
    setSplitPanelOpen(true);
    setSplitPanelHeader(CREATE_NEW_DOCUMENT);
    setOpenedDocument(undefined);
    setOpenedProjects(undefined);
    removeSearchParamOpenedDocumentTitle?.();
    setSplitPanelElement(
      <div key={DocumentFormSubmitAction.CREATE}>
        <DocumentForm
          project={project}
          submitAction={DocumentFormSubmitAction.CREATE}
          onCancel={() => closeSplitPanel()}
          onSubmit={({ document: createdDocument }) => {
            viewDocumentDetail({
              document: createdDocument,
              flashbarMessage: DOCUMENT_UPLOAD_SUCCESS_MESSAGE,
            });
            onDocumentChange?.(createdDocument);
          }}
        />
      </div>,
    );
  }

  function duplicateDocument({ document, project }: DuplicateDocumentProps) {
    setSplitPanelOpen(true);
    setSplitPanelHeader(CREATE_NEW_DOCUMENT);
    setOpenedDocument(undefined);
    setOpenedProjects(undefined);
    removeSearchParamOpenedDocumentTitle?.();
    setSplitPanelElement(
      <div key={`${DocumentFormSubmitAction.DUPLICATE}${document.documentId}`}>
        <DocumentForm
          project={project}
          document={document}
          submitAction={DocumentFormSubmitAction.DUPLICATE}
          onCancel={() => viewDocumentDetail({ document, project })}
          onSubmit={({ document: createdDocument }) => {
            viewDocumentDetail({
              document: createdDocument,
              flashbarMessage: DOCUMENT_UPLOAD_SUCCESS_MESSAGE,
            });
            onDocumentChange?.(createdDocument);
          }}
        />
      </div>,
    );
  }

  function handleProjectDetailActionItemClick({
    project,
    documents,
    event,
  }: ProjectDetailActionItemClickProps) {
    switch (event.detail.id) {
    case ProjectDetailAction.DELETE:
      handleDeleteProject?.({ project });
      break;
    case ProjectDetailAction.EXPORT:
      handleExportDocumentMetadataByProject?.({ project, documents });
      break;
    }
  }

  function viewProjectDetail({
    projects,
    flashbarMessage,
  }: ViewProjectDetailProps) {
    setOpenedDocument(undefined);

    if (projects.length > 1 && openedProjects) {
      setOpenedProjects(projects);
      setSplitPanelHeader(
        projects.map((project) => project.projectTitle).join(ARRAY_SEPARATOR),
      );
      setSplitPanelElement(undefined);
    } else if (projects.length === 1) {
      setOpenedProjects(projects);
      const project = projects[0];
      setSplitPanelHeader(project.projectTitle);
      setSplitPanelOpen(true);
      scrollSplitPanelContentToTop();
      setSplitPanelElement(
        <ProjectDetail
          project={project}
          flashbarMessage={flashbarMessage}
          onEditClick={editProject}
          onActionItemClick={handleProjectDetailActionItemClick}
        />,
      );
    }
  }

  function editProject({ project }: EditProjectProps) {
    setSplitPanelOpen(true);
    setSplitPanelHeader(EDIT_PROJECT);
    setOpenedDocument(undefined);
    setOpenedProjects([project]);
    setSplitPanelElement(
      <div key={`${ProjectFormSubmitAction.EDIT}${project.projectId}`}>
        <ProjectForm
          project={project}
          submitAction={ProjectFormSubmitAction.EDIT}
          onCancel={() => viewProjectDetail({ projects: [project] })}
          onSubmit={({ project: updatedProject, noChanges }) => {
            viewProjectDetail({
              projects: [updatedProject],
              flashbarMessage: noChanges
                ? PROJECT_NO_CHANGES_INFO_MESSAGE
                : PROJECT_EDIT_SUCCESS_MESSAGE,
            });
            !noChanges && onProjectChange?.(updatedProject);
          }}
        />
      </div>,
    );
  }

  function createProject() {
    setSplitPanelOpen(true);
    setSplitPanelHeader(CREATE_NEW_PROJECT);
    setOpenedDocument(undefined);
    setOpenedProjects(undefined);
    setSplitPanelElement(
      <div key={ProjectFormSubmitAction.CREATE}>
        <ProjectForm
          submitAction={ProjectFormSubmitAction.CREATE}
          onCancel={() => closeSplitPanel()}
          onSubmit={({ project: createdProject }) => {
            viewProjectDetail({
              projects: [createdProject],
              flashbarMessage: PROJECT_CREATE_SUCCESS_MESSAGE,
            });
            onProjectChange?.(createdProject);
          }}
        />
      </div>,
    );
  }

  function closeSplitPanel() {
    setSplitPanelOpen(false);
    setSplitPanelHeader("");
    setSplitPanelElement(undefined);
    setOpenedDocument(undefined);
    setOpenedProjects(undefined);
    removeSearchParamOpenedDocumentTitle?.();
  }

  function scrollSplitPanelContentToTop() {
    if (dashboardDivRef) {
      scrollSplitPanelContent({
        divRef: dashboardDivRef,
        splitPanelSelector,
        top: 0,
      });
    }
  }

  return {
    splitPanelElement,
    splitPanelHeader,
    closeSplitPanel,
    openedDocument,
    viewDocumentDetail,
    createDocument,
    duplicateDocument,
    editDocument,
    openedProjects,
    viewProjectDetail,
    createProject,
    editProject,
  };
}
