
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_fc3ml_141",
  "header": "awsui_header_l0dv0_fc3ml_178",
  "header-link": "awsui_header-link_l0dv0_fc3ml_191",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_fc3ml_201",
  "header-link-text": "awsui_header-link-text_l0dv0_fc3ml_201",
  "header-logo": "awsui_header-logo_l0dv0_fc3ml_206",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_fc3ml_212",
  "list-container": "awsui_list-container_l0dv0_fc3ml_217",
  "list": "awsui_list_l0dv0_fc3ml_217",
  "list-variant-root": "awsui_list-variant-root_l0dv0_fc3ml_229",
  "list-variant-root--last": "awsui_list-variant-root--last_l0dv0_fc3ml_236",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_fc3ml_240",
  "list-item": "awsui_list-item_l0dv0_fc3ml_244",
  "section": "awsui_section_l0dv0_fc3ml_252",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_fc3ml_253",
  "section--no-ident": "awsui_section--no-ident_l0dv0_fc3ml_256",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_fc3ml_257",
  "refresh": "awsui_refresh_l0dv0_fc3ml_265",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_fc3ml_273",
  "section-group": "awsui_section-group_l0dv0_fc3ml_280",
  "section-group-title": "awsui_section-group-title_l0dv0_fc3ml_291",
  "link": "awsui_link_l0dv0_fc3ml_295",
  "link-active": "awsui_link-active_l0dv0_fc3ml_304",
  "info": "awsui_info_l0dv0_fc3ml_341",
  "external-icon": "awsui_external-icon_l0dv0_fc3ml_345",
  "divider": "awsui_divider_l0dv0_fc3ml_349",
  "divider-default": "awsui_divider-default_l0dv0_fc3ml_354",
  "divider-header": "awsui_divider-header_l0dv0_fc3ml_360"
};
  