import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import Multiselect, {
  MultiselectProps,
} from "@cloudscape-design/components/multiselect";
import { useInput } from "../../hooks";
import { arrayToDict, flatten } from "../../helpers";
import CustomFormField from "./CustomFormField";
import styles from "./MultiSelectInput.module.scss";
import {
  BaseDropdownHostProps,
  BaseInputProps,
  BaseInputRefAttributes,
  BaseOptionDefinition,
  BaseOptionGroup,
  BaseSelectProps,
} from ".";

export interface MultiSelectInputProps<K extends string = string>
  extends BaseInputProps<string[], K>,
    BaseSelectProps {
  selectAllOption?: boolean;
  /**
   * Options for `filteringType` of `manual`.
   */
  manualFilterOptions?: BaseDropdownHostProps;
}

function MultiSelectInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    options,
    filteringType = "auto",
    constraintText,
    selectAllOption,
    manualFilterOptions = {},
    ...props
  }: MultiSelectInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { loading, error, onLoadItems } = manualFilterOptions;

  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<string[]>({
      validate,
      initialState: defaultValue,
    });

  const [statusType, setStatusType] =
    useState<MultiselectProps["statusType"]>("pending");

  const optionsByValue = useMemo(() => {
    const optionDefinitions = flatten<
      BaseOptionDefinition,
      BaseOptionGroup,
      "options"
    >(options ?? [], "options");
    return arrayToDict(optionDefinitions ?? [], "value");
  }, [options]);

  const inputRef = useRef<MultiselectProps.Ref>(null);

  useEffect(() => {
    if (loading) {
      setStatusType("loading");
    } else if (error) {
      setStatusType("error");
    } else {
      setStatusType("finished");
    }
  }, [loading, error]);

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
    };
  }, [handleBlur, inputRef]);

  function handleChange(value: string[]) {
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
  }

  return (
    <CustomFormField
      label={label}
      required={required}
      description={description}
      errorText={errorText}
      constraintText={constraintText}
    >
      <div className={selectAllOption ? styles.optionGroupReducedIndent : ""}>
        <Multiselect
          data-multiselect-selector="multiselect-input"
          selectedOptions={
            value !== undefined
              ? value.map((v) => ({
                value: v,
                label: optionsByValue[v]?.label ?? v,
              }))
              : []
          }
          options={
            selectAllOption
              ? [{ label: "Select all", options: options ?? [] }]
              : options
          }
          filteringType={filteringType}
          statusType={statusType}
          recoveryText="Retry"
          loadingText="Loading..."
          errorText="Error fetching results"
          ariaRequired={required}
          filteringAriaLabel="Filter"
          filteringClearAriaLabel="Clear"
          selectedAriaLabel="Selected"
          placeholder={placeholder}
          onLoadItems={onLoadItems}
          onChange={({ detail: { selectedOptions } }) => {
            handleChange(
              (selectedOptions as BaseOptionDefinition[]).map((v) => v.value),
            );
          }}
          onBlur={handleBlur}
          disabled={disabled}
          ref={inputRef}
          {...props}
        />
      </div>
    </CustomFormField>
  );
}

export default forwardRef(MultiSelectInput);

export function TypedMultiSelectInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, MultiSelectInputProps<K>>(
    MultiSelectInput,
  );
}
