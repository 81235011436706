export const ROLE_ATTRIBUTE_NAME = "custom:ROLE";

export enum UserRole {
  ADMIN = "admin",
  AUTHOR = "author",
  READER = "global-reader",
}

export interface UserIdentity {
  role: UserRole[];
  alias: string;
  email: string;
  givenName: string;
  familyName: string;
}
