
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1wzqe_1wbr4_141",
  "search-field": "awsui_search-field_1wzqe_1wbr4_176",
  "input-wrapper": "awsui_input-wrapper_1wzqe_1wbr4_182",
  "add-token": "awsui_add-token_1wzqe_1wbr4_186",
  "tokens": "awsui_tokens_1wzqe_1wbr4_193",
  "token-operator": "awsui_token-operator_1wzqe_1wbr4_198",
  "property-editor": "awsui_property-editor_1wzqe_1wbr4_202",
  "token-editor": "awsui_token-editor_1wzqe_1wbr4_203",
  "property-editor-form": "awsui_property-editor-form_1wzqe_1wbr4_208",
  "token-editor-form": "awsui_token-editor-form_1wzqe_1wbr4_209",
  "property-editor-field-property": "awsui_property-editor-field-property_1wzqe_1wbr4_212",
  "token-editor-field-property": "awsui_token-editor-field-property_1wzqe_1wbr4_213",
  "property-editor-field-operator": "awsui_property-editor-field-operator_1wzqe_1wbr4_216",
  "token-editor-field-operator": "awsui_token-editor-field-operator_1wzqe_1wbr4_217",
  "property-editor-field-value": "awsui_property-editor-field-value_1wzqe_1wbr4_220",
  "token-editor-field-value": "awsui_token-editor-field-value_1wzqe_1wbr4_221",
  "property-editor-cancel": "awsui_property-editor-cancel_1wzqe_1wbr4_224",
  "token-editor-cancel": "awsui_token-editor-cancel_1wzqe_1wbr4_225",
  "property-editor-submit": "awsui_property-editor-submit_1wzqe_1wbr4_228",
  "token-editor-submit": "awsui_token-editor-submit_1wzqe_1wbr4_229",
  "property-editor-actions": "awsui_property-editor-actions_1wzqe_1wbr4_232",
  "token-editor-actions": "awsui_token-editor-actions_1wzqe_1wbr4_233",
  "custom-content-wrapper": "awsui_custom-content-wrapper_1wzqe_1wbr4_251",
  "custom-control": "awsui_custom-control_1wzqe_1wbr4_255",
  "input": "awsui_input_1wzqe_1wbr4_182",
  "results": "awsui_results_1wzqe_1wbr4_263",
  "token-trigger": "awsui_token-trigger_1wzqe_1wbr4_268",
  "remove-all": "awsui_remove-all_1wzqe_1wbr4_273",
  "token-label": "awsui_token-label_1wzqe_1wbr4_274",
  "join-operation": "awsui_join-operation_1wzqe_1wbr4_275",
  "custom-filter-actions": "awsui_custom-filter-actions_1wzqe_1wbr4_276",
  "constraint": "awsui_constraint_1wzqe_1wbr4_280"
};
  