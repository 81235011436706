import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Autosuggest, {
  AutosuggestProps,
} from "@cloudscape-design/components/autosuggest";
import { useInput } from "../../hooks";
import CustomFormField from "./CustomFormField";
import {
  BaseDropdownHostProps,
  BaseInputProps,
  BaseInputRefAttributes,
  BaseSelectProps,
} from ".";

export interface AutosuggestInputProps<K extends string = string>
  extends BaseInputProps<string, K>,
    BaseSelectProps {
  /**
   * Options for `filteringType` of `manual`.
   */
  manualFilterOptions?: BaseDropdownHostProps;
}

function AutosuggestInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    options,
    filteringType = "auto",
    constraintText,
    manualFilterOptions = {},
    ...props
  }: AutosuggestInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { loading, error, onLoadItems } = manualFilterOptions;

  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<string>({
      validate,
      initialState: defaultValue,
    });
  const [statusType, setStatusType] =
    useState<AutosuggestProps["statusType"]>("pending");

  const inputRef = useRef<AutosuggestProps.Ref>(null);

  useEffect(() => {
    if (loading) {
      setStatusType("loading");
    } else if (error) {
      setStatusType("error");
    } else {
      setStatusType("finished");
    }
  }, [loading, error]);

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
    };
  }, [handleBlur, inputRef]);

  function handleChange(value: string) {
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
  }

  return (
    <CustomFormField
      label={label}
      required={required}
      description={description}
      errorText={errorText}
      constraintText={constraintText}
    >
      <Autosuggest
        value={value}
        options={loading || !value ? [] : options}
        enteredTextLabel={(value) => `Use: "${value}"`}
        filteringType={filteringType}
        statusType={statusType}
        recoveryText="Retry"
        loadingText="Loading..."
        errorText="Error fetching results"
        ariaRequired={required}
        selectedAriaLabel="Selected"
        placeholder={placeholder}
        onLoadItems={onLoadItems}
        onChange={({ detail: { value } }) => {
          handleChange(value);
        }}
        onBlur={handleBlur}
        disabled={disabled}
        ref={inputRef}
        {...props}
      />
    </CustomFormField>
  );
}

export default forwardRef(AutosuggestInput);

export function TypedAutosuggestInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, AutosuggestInputProps<K>>(
    AutosuggestInput,
  );
}
