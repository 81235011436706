import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown";
import { ProjectDetailAction } from "./projectDetailTypes";

interface GetProjectDetailContextMenuItemsProps {
  isEditor?: boolean;
}

export function getProjectDetailContextMenuItems({
  isEditor,
}: GetProjectDetailContextMenuItemsProps): ButtonDropdownProps.ItemOrGroup[] {
  return Object.values(ProjectDetailAction)
    .filter((action) =>
      action === ProjectDetailAction.DELETE ? isEditor : true,
    )
    .map((action) => ({
      id: action,
      text: action,
    }));
}
