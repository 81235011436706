import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import Textarea, {
  TextareaProps,
} from "@cloudscape-design/components/textarea";
import { useInput } from "../../hooks";
import CustomFormField from "./CustomFormField";
import { BaseInputProps, BaseInputRefAttributes } from ".";

export interface TextAreaInputProps<K extends string = string>
  extends BaseInputProps<string, K>,
    Pick<TextareaProps, "readOnly"> {}

function TextAreaInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    constraintText,
    ...props
  }: TextAreaInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<string>({
      validate,
      initialState: defaultValue,
    });

  const inputRef = useRef<TextareaProps.Ref>(null);

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
      overrideValue: (value: string) => {
        handleChange(value);
      },
    };
  }, [handleBlur, inputRef]);

  function handleChange(value: string) {
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
  }

  return (
    <CustomFormField
      label={label}
      required={required}
      description={description}
      errorText={errorText}
      constraintText={constraintText}
    >
      <Textarea
        value={value}
        ariaRequired={required}
        placeholder={placeholder}
        onChange={({ detail: { value } }) => {
          handleChange(value);
        }}
        onBlur={handleBlur}
        disabled={disabled}
        name={fieldId}
        ref={inputRef}
        {...props}
      />
    </CustomFormField>
  );
}

export default forwardRef(TextAreaInput);

export function TypedTextAreaInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, TextAreaInputProps<K>>(
    TextAreaInput,
  );
}
