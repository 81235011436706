import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown";
import { ProjectDashboardAction } from "./projectDashboardTypes";

interface GetProjectDashboardContextMenuItemsProps {
  numSelectedItems: number;
  isExporting?: boolean;
  isEditor?: boolean;
}

function getDisabledStatus(
  action: ProjectDashboardAction,
  numSelectedItems: number,
  isExporting?: boolean,
): { disabled: boolean; disabledReason?: string } {
  if (action !== ProjectDashboardAction.EXPORT && numSelectedItems !== 1) {
    return {
      disabled: true,
      disabledReason: "Please select a single project to perform this action",
    };
  }
  if (numSelectedItems === 0) {
    return {
      disabled: true,
      disabledReason:
        "Please select at least one project to perform this action",
    };
  }
  if (action === ProjectDashboardAction.EXPORT && isExporting) {
    return {
      disabled: true,
      disabledReason: "Document metadata export in progress, please wait...",
    };
  }
  return { disabled: false };
}

export function getProjectDashboardContextMenuItems({
  numSelectedItems,
  isExporting,
  isEditor,
}: GetProjectDashboardContextMenuItemsProps): ButtonDropdownProps.ItemOrGroup[] {
  return Object.values(ProjectDashboardAction)
    .filter((action) =>
      action === ProjectDashboardAction.EDIT ||
      action === ProjectDashboardAction.DELETE
        ? isEditor
        : true,
    )
    .map((action) => {
      const { disabled, disabledReason } = getDisabledStatus(
        action,
        numSelectedItems,
        isExporting,
      );
      return {
        id: action,
        text: action,
        disabled,
        disabledReason,
      };
    });
}
