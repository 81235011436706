import { useEffect } from "react";
import { CreateProjectInput, Project } from "../../../client/interfaces";
import { MessagesContextType } from "../../../context/MessagingContext";
import { useCreateProject, useUpdateProject } from "../../../hooks";
import {
  CREATE_PROJECT_ERROR,
  UPDATE_PROJECT_ERROR,
} from "../../../data/constants/errorMessage";
import {
  ProjectFormBoolean,
  ProjectFormSubmitAction,
  ProjectFormSubmitDetail,
} from "./projectFormTypes";

interface UseProjectFormSubmitProps {
  project?: Project;
  submitAction: ProjectFormSubmitAction;
  onSubmitAction?: (project?: Project) => void;
  onSubmitSuccess?: (detail: ProjectFormSubmitDetail) => void;
  onSubmitError?: (project?: Project) => void;
  addErrorMessage?: MessagesContextType["addErrorMessage"];
  projectInputValue: CreateProjectInput;
  projectFormIsError: React.MutableRefObject<ProjectFormBoolean>;
  projectFormIsModified: React.MutableRefObject<ProjectFormBoolean>;
}

export function useProjectFormSubmit({
  project,
  submitAction,
  onSubmitAction,
  onSubmitSuccess,
  onSubmitError,
  addErrorMessage,
  projectInputValue,
  projectFormIsError,
  projectFormIsModified,
}: UseProjectFormSubmitProps) {
  const {
    trigger: createProject,
    isMutating: isCreatingProject,
    error: createProjectError,
  } = useCreateProject();
  const {
    trigger: updateProject,
    isMutating: isUpdatingProject,
    error: updateProjectError,
  } = useUpdateProject(project?.projectId);

  useEffect(() => {
    if (createProjectError) {
      addErrorMessage?.(createProjectError, CREATE_PROJECT_ERROR);
    }
    if (updateProjectError) {
      addErrorMessage?.(updateProjectError, UPDATE_PROJECT_ERROR);
    }
  }, [createProjectError, updateProjectError]);

  async function submit() {
    try {
      let newOrUpdatedProject: Project = project as Project;
      let isModified = true;

      switch (submitAction) {
      case ProjectFormSubmitAction.CREATE:
        newOrUpdatedProject = await createProject(projectInputValue);
        break;
      case ProjectFormSubmitAction.EDIT:
        isModified = Object.values(projectFormIsModified.current).some(
          (value) => value,
        );
        if (isModified && project) {
          newOrUpdatedProject = await updateProject({
            ...project,
            ...projectInputValue,
          });
        }
        break;
      }

      onSubmitSuccess?.({
        project: newOrUpdatedProject,
        noChanges: !isModified,
      });
    } catch (e) {
      // Error handled in flashbar messages
      onSubmitError?.(project);
    }
  }

  async function handleSubmit() {
    onSubmitAction?.(project);
    if (Object.values(projectFormIsError.current).some((value) => value)) {
      onSubmitError?.(project);
    } else {
      await submit();
    }
  }

  return {
    handleSubmit,
    isSubmitting: isCreatingProject || isUpdatingProject,
  };
}
