interface ScrollSplitPanelContentToTopProps {
  divRef: React.RefObject<HTMLDivElement>;
  splitPanelSelector: string;
  top?: number;
}

export function scrollSplitPanelContent({
  divRef,
  splitPanelSelector,
  top = 0,
}: ScrollSplitPanelContentToTopProps) {
  if (divRef.current) {
    divRef.current
      .querySelector(`${splitPanelSelector} > div > div[aria-hidden="false"]`)
      ?.scrollTo?.({ top });
  }
}
