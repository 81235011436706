import React, { FC } from "react";
import { Spinner } from "@cloudscape-design/components";
import styles from "./ScreenLoader.module.css";

interface ScreenLoaderProps {
  message?: string;
}

const ScreenLoader: FC<ScreenLoaderProps> = ({
  message = "Loading, please wait...",
}) => {
  return (
    <div className={styles.loaderContainer} data-testid="loader">
      <Spinner size="large" />
      <h1>{message}</h1>
    </div>
  );
};

export default ScreenLoader;
