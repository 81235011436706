import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Project } from "../../../client/interfaces";
import { DEFAULT_DEBOUNCE_DELAY_MILLIS } from "../../../data/constants/common";
import { ProjectFormBoolean } from "../../panel/projectForm";

interface UseDebouncedProjectTitleProps {
  allProjects?: Project[];
  initialProjectTitleExists?: boolean;
  projectFormIsModified?: React.MutableRefObject<ProjectFormBoolean>;
  onDebouncedProjectTitleChange?: (
    projectTitle: string,
    projectTitleExists: boolean,
  ) => void;
}

export function useDebouncedProjectTitle({
  allProjects,
  initialProjectTitleExists,
  projectFormIsModified,
  onDebouncedProjectTitleChange,
}: UseDebouncedProjectTitleProps) {
  const projectTitleExists = useRef(initialProjectTitleExists);
  const debounceProjectTitleExists = useDebouncedCallback((value: string) => {
    if (
      projectFormIsModified?.current.projectTitle &&
      allProjects?.find((project) => project.projectTitle === value)
    ) {
      projectTitleExists.current = true;
    } else {
      projectTitleExists.current = false;
    }

    onDebouncedProjectTitleChange?.(value, projectTitleExists.current);
  }, DEFAULT_DEBOUNCE_DELAY_MILLIS);

  return {
    projectTitleExists,
    debounceProjectTitleExists,
  };
}
