import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "@cloudscape-design/global-styles/index.css";
import "./index.css";
import { Amplify } from "aws-amplify";
import { AuthContextProvider } from "./context/AuthContext";
import App from "./App";
import MessagesProvider from "./context/MessagingContext";
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";
import { getAuthConfig } from "./data/config/authConfig";
import { getSettings } from "./client/api/settings";

(async () => Amplify.configure({ ...getAuthConfig(await getSettings()) }))();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // StrictMode checks are development-only and do not impact the production build.
  <React.StrictMode>
    <ErrorBoundary>
      <AuthContextProvider>
        <BrowserRouter>
          <MessagesProvider>
            <App />
          </MessagesProvider>
        </BrowserRouter>
      </AuthContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
