import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown";
import { DocumentDashboardAction } from "./documentDashboardTypes";

interface GetDocumentDashboardContextMenuItemsProps {
  isEditor?: boolean;
  numSelectedItems: number;
}

function getDisabledStatus(
  action: DocumentDashboardAction,
  numSelectedItems: number,
): { disabled: boolean; disabledReason?: string } {
  if (action !== DocumentDashboardAction.EXPORT && numSelectedItems !== 1) {
    return {
      disabled: true,
      disabledReason: "Please select a single document to perform this action",
    };
  }
  if (numSelectedItems === 0) {
    return {
      disabled: true,
      disabledReason:
        "Please select at least one document to perform this action",
    };
  }
  return { disabled: false };
}

export function getDocumentDashboardContextMenuItems({
  isEditor,
  numSelectedItems,
}: GetDocumentDashboardContextMenuItemsProps): ButtonDropdownProps.ItemOrGroup[] {
  return Object.values(DocumentDashboardAction)
    .filter((action) =>
      action === DocumentDashboardAction.EDIT ||
      action === DocumentDashboardAction.DELETE ||
      action === DocumentDashboardAction.DUPLICATE
        ? isEditor
        : true,
    )
    .map((action) => {
      const { disabled, disabledReason } = getDisabledStatus(
        action,
        numSelectedItems,
      );
      return {
        id: action,
        text: action,
        disabled,
        disabledReason,
      };
    });
}
