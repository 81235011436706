import { useSearchParams } from "react-router-dom";

export function useOpenedDocumentSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  function setOpenedDocumentTitle(title: string) {
    if (searchParams.get("documentTitle") !== title) {
      searchParams.set("documentTitle", title);
      setSearchParams(searchParams, { replace: true });
    }
  }

  function removeOpenedDocumentTitle() {
    if (searchParams.get("documentTitle") !== null) {
      searchParams.delete("documentTitle");
      setSearchParams(searchParams, { replace: true });
    }
  }

  return {
    setOpenedDocumentTitle,
    removeOpenedDocumentTitle,
    openedDocumentTitle: searchParams.get("documentTitle"),
  };
}
