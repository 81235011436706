export enum ProjectDashboardAction {
  EDIT = "Edit",
  DELETE = "Delete",
  SHARE_SELECTED = "Copy project URL",
  EXPORT = "Export metadata",
}

export enum ProjectDashboardCreateOption {
  PROJECT = "Project",
  DOCUMENT = "Document",
}
